import {IProtocol} from './protocol-interface';
import {Answer_CustomSessionError, Answer_LoginSuccess, Command_Login} from '../classes.g';
import {CommandIdGenerator} from '../command-id-generator';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {ProtocolMediatorBase_CommandBase} from './protocol-mediator-base-command-base';
import {DisconnectionCodes} from '@common/communication/connection/disconnection-code';
import * as crypto from 'crypto-js';
import {Trader} from '@common/trader/models/trader';
import {environment} from '../../../../environments/environment';

export class ProtocolAuthByLoginName extends ProtocolMediatorBase_CommandBase {
  private _loginMessage: Command_Login;
  private readonly _loginName: string;
  private readonly _passwordHash: string;
  private readonly _linkId: string;

  public constructor (subProtocol: IProtocol, commandIDGenerator: CommandIdGenerator, loginName: string, password: string, linkId: string) {
    super(subProtocol, commandIDGenerator);
    this._loginName = loginName;

    const md5Hash = crypto.MD5(password).toString();    //  .createHash('md5').update(password).digest('hex');

    this._passwordHash =  md5Hash;
    this._linkId = linkId;
  }

  public onConnectionEstablished() {
    this._loginMessage = new Command_Login();
    this._loginMessage.AppName = 'WebTrader';
    this._loginMessage.LoginName = this._loginName;
    this._loginMessage.PasswordHash = this._passwordHash;
    this._loginMessage.CommandID = this._commandIDGenerator.getNextCommandID();
    this._loginMessage.ClientData = JSON.stringify(this.createClientData());
    this._loginMessage.LinkID = this._linkId;
    console.log('sending Command_Login message');
    // console.log('this._loginMessage', 23, this._loginMessage);
    this.send(this._loginMessage);
  }

  public onMessageReceived(message: ProtoV2MessageBase) {
    if (this.isAnswerToCommand(this._loginMessage, message)) {
      this._logger.debug('Got new answer');
      this.handleLoginAnswer(message);
    } else {
      super.onMessageReceived(message);
    }
  }

  private handleLoginAnswer(message: ProtoV2MessageBase) {
    if (message.MessageType == 'Answer_LoginSuccess') {
      this._logger.debug('LoginSuccess');
      this.handleLoginAnswer_Success(message as Answer_LoginSuccess);
    } else if (message.MessageType == 'Answer_CustomSessionError') {
      this._logger.error('CustomSessionError');
      this.handleLoginAnswer_Failed(message as Answer_CustomSessionError);
    }
  }

  private handleLoginAnswer_Success(message: Answer_LoginSuccess) {
    super.onMessageReceived(message);
    super.onConnectionEstablished();
  }

  private handleLoginAnswer_Failed(message: Answer_CustomSessionError) {
    super.onMessageReceived(message);
    super.onConnectionEstablishedError(this.getErrorCode(message.Error.Code));
  }


  private getErrorCode(messageErrorCode): DisconnectionCodes {
    if (messageErrorCode == '50106') {
      return DisconnectionCodes.AccountIsLocked;
    }
    if (messageErrorCode == '1') {
      return DisconnectionCodes.IncorrectCredentials;
    }
    return DisconnectionCodes.RemoteDisconnect;
  }
}
