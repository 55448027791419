<div class="challenge-box" *ngIf="showChallengeBox()">
  <div class="grid-box">

    <!--      объявление об результатах испытания-->

    <!--    <div class="grid-result" *ngIf="!getShowTradeDayStartBalance()">-->
    <!--      <mat-grid-list cols="3" rowHeight="40">-->
    <!--        <mat-grid-tile [colspan]="3" [rowspan]="1" *ngIf="getCompletionChallengeFinish()">-->
    <!--          <span class="completion-passed" >The stage is passed, Congratulations!</span>-->
    <!--        </mat-grid-tile>-->
    <!--        <mat-grid-tile [colspan]="3" [rowspan]="1" *ngIf="getShowNotificationLosingChallenge()">-->
    <!--          <span class="losing-failed">Account has been Closed. Please try again</span>-->
    <!--        </mat-grid-tile>-->

    <!--&lt;!&ndash;        <mat-grid-tile [colspan]="3" [rowspan]="1" *ngIf="getResultCloseReason()">&ndash;&gt;-->
    <!--&lt;!&ndash;          <span class="losing-failed">{{getResultCloseReasonText()}}</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </mat-grid-tile>&ndash;&gt;-->
    <!--      </mat-grid-list>-->
    <!--    </div>-->

    <!--      секция отображения баланса на начало дня-->

    <div class="grid-result">
      <mat-grid-list cols="10" rowHeight="40" class="challenge-box-item" *ngIf="!IsReadOnlyAccount">
        <mat-grid-tile [colspan]="5" [rowspan]="1">
          <l-unit class="font-style title-padding" [key]="'Challenge_ChallengeComponent_TodayStartingEquity'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="1">
          <span>{{getTradeDayStartBalance()}}</span>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="10" rowHeight="40" class="challenge-box-item" *ngIf="IsReadOnlyAccount">
        <mat-grid-tile [colspan]="4" [rowspan]="1">
          <l-unit class="font-style title-padding" [key]="'Challenge_ChallengeComponent_StartingEquity'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="1">
          <span>{{getTradeDayStartBalance()}}</span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
          <span> on {{getDateCloseAccountChallenge()}}</span>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!--      секция отображения начального баланса-->

    <div class="grid-balance">
      <mat-grid-list cols="5" rowHeight="40" class="challenge-box-item">
        <mat-grid-tile [colspan]="2" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_InitialBalance'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="1">
          <span>{{getInitialsBalance()}}</span>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!--       секция отображения дат-->

    <div class="grid-days">
      <mat-grid-list cols="3" rowHeight="40" class="challenge-box-item">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_StartDate'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_EndDate'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_DaysPassed'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <span>{{ getStarDateChallenge() }}</span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <span [class.losing-failed]="getDeadline()">{{ getEndDateChallenge() }}</span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <span *ngIf="getSowInstantFundingGroupsChallenge()">NA</span>
          <div *ngIf="!getSowInstantFundingGroupsChallenge()" class="days-passed-container">
            <span *ngIf="getDeadline()" class="completion-icon-box-deadLine"><span
              class="completion-icon losing-icon-color losing-icon-margin">&#10006;</span></span>
            <div *ngIf="!getDeadline()" class="days-passed-box">
              <mat-grid-list cols="2" rowHeight="40" style="width: 100%">
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <span>{{ getDaysPassed() }} / {{ getTotalDays() }}</span>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <circle-progress-bar [color]="ColorCompletion"
                                           [progress]="getDaysPassedProgress()"></circle-progress-bar>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!--      секция отображения прибыли-->

    <div class="grid-profit">
      <mat-grid-list cols="6" rowHeight="40" class="challenge-box-item" *ngIf="getConfigCompletionChallengeInDollar() == 0">
        <mat-grid-tile [colspan]="6" [rowspan]="2">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_NoProfitTarget'"></l-unit>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="6" rowHeight="40" class="challenge-box-item" *ngIf="getConfigCompletionChallengeInDollar() > 0">
        <mat-grid-tile [colspan]="2" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_ProfitTarget'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1" class="mat-grid-tile-progress-bar-box">
            <span class="progress-bar-box">
              <span class="challenge-progress-bar-value"> $ {{ getConfigCompletionChallengeInDollar() }}</span>
              <span class="challenge-progress-bar-value"> {{ getConfigCompletionChallengeInPercent() }} % </span>
            </span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="2">
          <div *ngIf="getSowInstantFundingGroupsChallengeProfit()">
            <span *ngIf="getCompletionChallengeFinish()" class="completion-icon-box">
              <span class="completion-icon  completion-icon-color">&#10003;</span>
            </span>
            <span *ngIf="!getCompletionChallengeFinish()" class="challenge-progress-bar-box">
              <circle-progress-bar [color]="ColorCompletion"
                                   [progress]="getCompletionChallengeProgressBarInPercent()"></circle-progress-bar>
            </span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_CurrentValue'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1" class="mat-grid-tile-progress-bar-box">
            <span class="progress-bar-box">
              <span class="challenge-progress-bar-value"
                    [class.positive]="getCompletionChallengeProgressInDollar() > 0"> $ {{ getCompletionChallengeProgressInDollar() }}</span>
              <span class="challenge-progress-bar-value"
                    [class.positive]="getCompletionChallengeProgressInDollar() > 0"> {{ getCompletionChallengeProgressInPercent() }} % </span>
            </span>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!--      секция отображения проигрыша-->

    <div class="grid-drawdown">
      <mat-grid-list cols="12" rowHeight="40" class="challenge-box-item">
        <mat-grid-tile [colspan]="6" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_MaxDrawdown'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="1" class="mat-grid-tile-progress-bar-box">
            <span class="progress-bar-box">
              <span class="challenge-progress-bar-value"> $ {{ getConfigLosingChallengeInDollar() }}</span>
              <span class="challenge-progress-bar-value"> {{ getConfigLosingChallengeInPercent() }} % </span>
            </span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="2">
          <span *ngIf="getLosingChallengeFinish()" class="completion-icon losing-icon-color">&#10006;</span>
          <span *ngIf="!getLosingChallengeFinish()" class="challenge-progress-bar-box">
                <circle-progress-bar [color]="ColorLosing"
                                         [progress]="getLosingChallengeProgressBarInPercent()"></circle-progress-bar>
              </span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="6" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_CurrentValue'"
                  [tooltipCustom]="getLocalPhrase('Challenge_ChallengeComponent_CurrentValueWithFee')"
                  position="below"
                  showDelay="300"
                  hideDelay="500"
          ></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="1" class="mat-grid-tile-progress-bar-box">
            <span class="progress-bar-box">
              <span class="challenge-progress-bar-value"
                    [class.negative]="getLosingChallengeProgressInDollar() > 0"> $ {{ getLosingChallengeProgressInDollar() }}</span>
              <span class="challenge-progress-bar-value"
                    [class.negative]="getLosingChallengeProgressInDollar() > 0"> {{ getLosingChallengeProgressInPercent() }}
                % </span>
            </span>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!--      дневные значения убытков-->

    <div class="grid-daily-loss">
      <mat-grid-list cols="12" rowHeight="40" class="challenge-box-item">
        <mat-grid-tile [colspan]="6" [rowspan]="1">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_MaxDailyLoss'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="1" class="mat-grid-tile-progress-bar-box">
            <span class="progress-bar-box">
              <span class="challenge-progress-bar-value"> $ {{ getConfigDailyDrawdownInDollar() }}</span>
              <span class="challenge-progress-bar-value"> {{ getConfigDailyDrawdownPercent() }} % </span>
            </span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="2">
          <span *ngIf="getLosingChallengeFinishToday()" class="completion-icon losing-icon-color">&#10006;</span>
          <span *ngIf="!getLosingChallengeFinishToday()" class="challenge-progress-bar-box">
                <circle-progress-bar [color]="ColorLosing"
                                         [progress]="getLosingChallengeProgressBarInPercentToday()"></circle-progress-bar>
              </span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="6" [rowspan]="1" [class.column-title]="IsReadOnlyAccount" >
          <l-unit class="font-style"
                  [class.non-margin]="IsReadOnlyAccount"
                  [key]="'Challenge_ChallengeComponent_TodayLoss'"
                  [tooltipCustom]="getLocalPhrase('Challenge_ChallengeComponent_TodayLossWithFee')"
                  position="below"
                  showDelay="300"
                  hideDelay="500"
                  *ngIf="!ShowDataDailyLossIsReadOnlyAccount"
          ></l-unit>
          <l-unit class="font-style"
                  [class.non-margin]="IsReadOnlyAccount"
                  [key]="'Challenge_ChallengeComponent_LossOn'"
                  [tooltipCustom]="getLocalPhrase('Challenge_ChallengeComponent_TodayLossWithFee')"
                  position="below"
                  showDelay="300"
                  hideDelay="500"
                  *ngIf="ShowDataDailyLossIsReadOnlyAccount"
          ></l-unit>
          <span *ngIf="ShowDataDailyLossIsReadOnlyAccount"> {{getDateCloseAccountChallenge()}}</span>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="1" class="mat-grid-tile-progress-bar-box">
            <span class="progress-bar-box">
              <span class="challenge-progress-bar-value"
                    [class.negative]="getTodayGainLossInDollar() > 0"> $ {{getTodayGainLossInDollar()}}</span>
              <span class="challenge-progress-bar-value"
                    [class.negative]="getTodayGainLossInDollar() > 0"> {{ getTodayGainLossPercent() }} % </span>
            </span>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!--        отображения графика испытания         -->

    <div class="grid-charts" *ngIf="showGraph()">
      <mat-grid-list cols="8" rowHeight="20" class="challenge-box-item">
        <mat-grid-tile [colspan]="8" [rowspan]="2">
          <l-unit class="font-style" [key]="'Challenge_ChallengeComponent_DailyChangeInEquity'"></l-unit>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="8" [rowspan]="8" class="mat-grid-tile-progress-bar-box chart-bar-box">
          <span class="xAxis-stroke"></span>
          <div class="scroll-charts" #scrollMe [scrollLeft]="scrollMe.scrollWidth" >
            <ngx-charts-bar-vertical-stacked
              [view]="view"
              [customColors]="CustomColors"
              [results]="MultiArray"
              [gradient]="Gradient"
              [xAxis]="ShowXAxis"
              [yAxis]="ShowYAxis"
              [legend]="ShowLegend"
              [showXAxisLabel]="ShowXAxisLabel"
              [showYAxisLabel]="ShowYAxisLabel"
              [animations]="Animations"
              [showDataLabel]="ShowDataLabel"
              [tooltipDisabled]="TooltipDisabled"
              [barPadding]="barPadding"
              [noBarWhenZero]="NoBarWhenZero">
            </ngx-charts-bar-vertical-stacked>
          </div>
          <div class="scroll-charts-yAxis">
            <ngx-charts-bar-vertical-stacked
              [view]="view"
              [customColors]="CustomColorsYAxis"
              [results]="MultiArray"
              [xAxis]="ShowXAxis"
              [yAxis]="ShowYAxis"
              [legend]="ShowLegend"
              [showYAxisLabel]="ShowYAxisLabel"
              [animations]="Animations"
              [showDataLabel]="ShowDataLabel">
            </ngx-charts-bar-vertical-stacked>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>

</div>
