import {Component, OnInit} from '@angular/core';
import {AppConfig} from '@common/configuration/app-config';
import {Environment} from '@common/environment';
import {ThemeService} from '@common/shared/services/theme';
import {LoginService} from '@common/auth/services/login.service';


// TODO: split this component for form login component and sign-in page

@Component({
  selector: 'app-signin',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {


  private _isConnected = true;
  private _hashLastUpdate: string;
  private _timeLastUpdate: any;
  private readonly _versionLastUpdate;
  private _checkInfoLastUpdate = false;

  get HashLastUpdate(): string {
    return this._hashLastUpdate;
  }

  get TimeLastUpdate() {
    return this._timeLastUpdate;
  }

  get CheckInfoLastUpdate(): boolean {
    return this._checkInfoLastUpdate;
  }

  public get IsConnected(): boolean {
    return this._isConnected;
  }

  public get Version(): string {
    // дополнительная проверка конфигов для карбонов график как на дестопе и текст другой
    if (this.config.Settings.common.AppInstallMessageName !== undefined && this.config.Settings.common.AppInstallMessageName === 'Carbon Trader') {
      return `${this._versionLastUpdate} - tv-mobile`;
    } else {
      return `${this._versionLastUpdate}-${Environment.ChartType}`;
    }
  }

  private static ConvertVersionToNumber(version: string): number {
    let sum = 0;
    let count = 1;
    const versionArray = version.split('.');

    versionArray.forEach((item: string) => {
      if (count === 1) {
        sum = Number(item) * 100000;
      } else if (count === 2) {
        sum = sum + Number(item) * 10000;
      } else if (count === 3) {
        sum = sum + Number(item) * 1000;
      } else if (count === 4) {
        sum = sum + Number(item);
      }
      count ++;
    });

    return sum;
  }

  constructor(private config: AppConfig,
              private themeService: ThemeService,
              private loginService: LoginService,
              /*, private netPositionService: NetPositionsService*/) {
//    netPositionService.clearAll();

    if (this.config.Settings.carbonTheme !== undefined && this.config.Settings.carbonTheme) {
      this.themeService.setTheme('tvCarbonTheme');
    }

    const activeTheme = localStorage.getItem('theme');

    if (this.config.Settings.colorThemeChangeMode !== undefined && this.config.Settings.colorThemeChangeMode) {
      if (activeTheme !== null) {
        if (this.config.Settings.common.AppName === 'Zetradex') {
          if (activeTheme === 'zetradexDarkTheme' || activeTheme === 'tvZetradexDarkTheme') {
            this.themeService.setTheme('tvZetradexDarkTheme');
          }
          if (activeTheme === 'zetradexLightTheme' || activeTheme === 'tvZetradexLightTheme') {
            this.themeService.setTheme('tvZetradexLightTheme');
          }
          if (activeTheme === 'tvDefaultTheme' || activeTheme === 'tvDarkTheme' || activeTheme === 'tvLightTheme') {
            this.themeService.setTheme('tvZetradexDarkTheme');
          }
        } else {
          if (activeTheme === 'tvDefaultTheme' || activeTheme === 'dark' || activeTheme === 'light') {
            this.themeService.setTheme('tvDarkTheme');
          } else {
            this.themeService.setTheme(activeTheme);
          }
        }
      } else {
        if (this.config.Settings.common.AppName === 'Zetradex') {
          this.themeService.setTheme('tvZetradexDarkTheme');
        } else {
          this.themeService.setTheme('tvDarkTheme');
        }
      }
    } else {
      this.themeService.setTheme('tvDarkTheme');
    }

    const configVersionApp = this.config.Settings.AppVersion ?? '1.0.0.0';
    const infoLastUpdate = JSON.parse(localStorage.getItem('InfoUpdateVersionApp'));

    if (infoLastUpdate !== null && infoLastUpdate.version) {
      if (SignInComponent.ConvertVersionToNumber(infoLastUpdate.version) >= SignInComponent.ConvertVersionToNumber(configVersionApp)) {
        this._versionLastUpdate = infoLastUpdate.version;
        sessionStorage.setItem('needReloadAppNewVersion', 'false');
      } else {
        this._versionLastUpdate = configVersionApp;
        sessionStorage.setItem('needReloadAppNewVersion', 'true');
      }

      localStorage.removeItem('InfoUpdateVersionApp');

    } else {
      this._versionLastUpdate = configVersionApp;
      sessionStorage.setItem('needReloadAppNewVersion', 'false');
    }
  }

  ngOnInit() {
    if (sessionStorage.getItem('Disconnected') !== null ) {
      this._isConnected = JSON.parse(sessionStorage.getItem('Disconnected'));
      setInterval(() => {
        this._isConnected = true;
        sessionStorage.removeItem('Disconnected');
      }, 3000);
    }
  }

  get IsCreateAccountLinkAvailable(): boolean {
    if (!this.config.Settings.connection.AllowCreatingAccount) {
      return false;
    }

    const onlineRegistrationUrl = this.OnlineRegistrationUrl;
    const demoSignUpUrl = this.SignUpUrl;
    return (onlineRegistrationUrl && onlineRegistrationUrl.length > 0) || (demoSignUpUrl && demoSignUpUrl.length > 0);
  }

  get IsShowButtonClearCache(): boolean {
    if (this.config.Settings.showButtonClearCache !== undefined) {
      return this.config.Settings.showButtonClearCache;
    }
    return false;
  }

  get CreateAccountLink(): string {
    const onlineRegistrationUrl = this.OnlineRegistrationUrl;
    if (onlineRegistrationUrl && onlineRegistrationUrl.length > 0) {
      return onlineRegistrationUrl;
    }

    const demoSignUpUrl = this.SignUpUrl;
    if (demoSignUpUrl && demoSignUpUrl.length > 0) {
      return demoSignUpUrl;
    }

    return '';
  }

  private get OnlineRegistrationUrl(): string {
    return AppConfig.settings.urls.OnlineRegistrationUrl;
  }

  private get SignUpUrl(): string {
    return AppConfig.settings.urls.DemoSignUpUrl;
  }

  public  getLogoCurrentThemeColor() {
    if ( this.themeService.getActiveTheme().category === 'dark' ) {
      return 'login-logo-dark-theme';
    } else {
      return 'login-logo-light-theme';
    }
  }

  public  getLogoTransitionCurrentThemeColor() {
    if ( this.themeService.getActiveTheme().category === 'dark' ) {
      return 'transition-logo-dark-theme';
    } else {
      return 'transition-logo-light-theme';
    }
  }

  public isShowSectionTransition(): boolean {
    if (this.config.Settings.connection.GoToUrl) {
      return true;
    }
    return false;
  }

  get GoToLink(): string {
    const goToLink = this.config.Settings.connection.GoToUrl;
    if (goToLink && goToLink.length > 0) {
      return goToLink;
    }
    return '';
  }

  get GoToName(): string {
    const name = this.config.Settings.connection.GoToNameUrl;
    if (name && name.length > 0) {
      return name;
    }
    return '';
  }

  clearCacheReloadPage() {
    localStorage.clear();
    location.reload();
  }

  isMobile() {
    return Environment.IsMobileVersion;
  }

  public isZeTradex() {
    return this.config.Settings.common.AppName === 'Zetradex';
  }

  public get isConnecting(): boolean {
    return this.loginService.isConnecting;
  }
}
