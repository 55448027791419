import {Injectable} from '@angular/core';
import {LocaleEnum} from '../../locale/servises/locale';
import {Session} from '@common/auth/models/session';
import {Settings} from '@common/trader/models/settings';
import { SymbolsSettingsStorage} from '@common/shared/services/SymbolSelect/symbol-settings';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {Notification} from '@common/notification/models/notification';
import {UsersInSystemsService} from '@common/trade/services/users-in-systems.service';
import {UsersGeoData} from '@common/trade/services/location-position.service';

export type SettingsStorage = Map<number, Settings>;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private static sessionUpdated: SmartEmitter<void> = new SmartEmitter<void>();
  private static viewToOpenKey = 'viewToOpen';
  private static sumAllTemplateIsOpen = 'sumAllTemplateIsOpen';
  private static templatesToDisplay = 'templatesToDisplay';
  private static openOnlyOneWindow = 'OpenOnlyOneWindow';
  private static savedViewData = 'savedViewData';
  private static ignoredNotificationsKey = 'ignoredNotifications';
  private static hashSumMainView = 'hashSumMainView';

  private usersInSystemsService: UsersInSystemsService;

  constructor(usersInSystemsService: UsersInSystemsService) {
    this.usersInSystemsService = usersInSystemsService;
  }

  private save(key: string, value: string): void {
    localStorage.setItem(key, value);
  }
  private remove(key: string): void {
    localStorage.removeItem(key);
  }
  private get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public getDataFromStorage(storageKey: string): string | null {
    return this.get(storageKey);
  }
  public saveDataInStorage(storageKey: string, data: string): void {
    this.save(storageKey, data);
  }
  public deleteDataInStorage(storageKey: string): void {
    this.remove(storageKey);
  }

  public removeSession() {
    this.remove('Session');
  }

  public getSession(): Session | null {
    const session = this.get('Session');

    if (!session) {
      return null;
    }

    const parsed = JSON.parse(session);

    return new Session(
      parsed.accessKey,
      new Date(parsed.expirationTime),
      parsed.timeValidity,
      parsed.traderId,
      parsed.login
    );
  }

  public saveSession(token: Session) {
    this.save('Session', JSON.stringify(token));
    this.usersInSystemsService.saveUserInSystemsWithToken(token);
    LocalStorageService.sessionUpdated.emit();
  }

  public getSessionKey(): string | null {
    return this.get('SessionId');
  }
  public saveSessionKey(key: string) {
    this.save('SessionId', key);
  }
  public deleteSessionKey() {
    this.remove('SessionId');
  }

  public getLocale(): LocaleEnum | null {
    return <LocaleEnum>this.get('locale');
  }
  public saveLocale(locale: LocaleEnum) {
    this.save('locale', locale);
  }

  public saveInstallDeclined(flag: boolean) {
    this.save('installDeclined', flag.toString());
  }
  public getInstallDeclined(): boolean {
    const value = this.get('installDeclined');

    if (!value) { return false; }

    return value === 'true';
  }

  public getSymbolSettingsStorage(traderName: string): SymbolsSettingsStorage {
    const dict = this.getSymbolSettingsDict();

    if (dict) {
      const symbolsSettingsStorages = <SymbolsSettingsStorage>dict[traderName];

      // добавил условия чтоб все значения были true для корректного отображения динамической категории символов
      if (symbolsSettingsStorages) {
        if (symbolsSettingsStorages.showMetals === undefined || symbolsSettingsStorages.showMetals === false) {
          symbolsSettingsStorages.showMetals = true;
        }
        if (symbolsSettingsStorages.showCFD === undefined || symbolsSettingsStorages.showCFD === false) {
          symbolsSettingsStorages.showCFD = true;
        }
        if (symbolsSettingsStorages.showForex === undefined || symbolsSettingsStorages.showForex === false) {
          symbolsSettingsStorages.showForex = true;
        }
        if (symbolsSettingsStorages.showCryptos === undefined || symbolsSettingsStorages.showCryptos === false) {
          symbolsSettingsStorages.showCryptos = true;
        }
        if (symbolsSettingsStorages.showEqities === undefined || symbolsSettingsStorages.showEqities === false) {
          symbolsSettingsStorages.showEqities = true;
        }
        if (symbolsSettingsStorages.showEnergies === undefined || symbolsSettingsStorages.showEnergies === false) {
          symbolsSettingsStorages.showEnergies = true;
        }
        if (symbolsSettingsStorages.showFutures === undefined || symbolsSettingsStorages.showFutures === false) {
          symbolsSettingsStorages.showFutures = true;
        }
        if (symbolsSettingsStorages.showIndices === undefined || symbolsSettingsStorages.showIndices === false) {
          symbolsSettingsStorages.showIndices = true;
        }
      }

      return dict[traderName];
    } else { return undefined; }
  }
  public saveSymbolSettingsStorage(traderName: string, settings: SymbolsSettingsStorage) {
    let dict = this.getSymbolSettingsDict();

    if (!dict) {
      dict = {};
    }

    dict[traderName] = settings;

    this.saveSymbolSettingsDict(dict);
  }

  private getSymbolSettingsDict() {
    const json = this.get('SymbolSettingsDict');

    if (!json) { return undefined; }

    const dict = JSON.parse(json);

    return dict;
  }
  private saveSymbolSettingsDict(dict: object) {
    const json = JSON.stringify(dict);
    this.save('SymbolSettingsDict', json);
  }

  public getSettingsStorage(): SettingsStorage {
    const json = this.get('SettingsStorage');

    const storage = new Map<number, Settings>();

    const settingsArray = JSON.parse(json);

    if (settingsArray) {
      settingsArray.forEach(item => {
        storage.set(item.key, item.value);
      });
    }

    return storage;
  }

  // метод для проверки циклической зависимости в объекте при обработке JSON.stringify
  private getCircularReplacer () {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  public saveSettingsStorage(settings: SettingsStorage) {
    const data = Array.from(settings.keys()).map(item => {
      return this.convertSettings(item, settings.get(item));
    });

    this.save('SettingsStorage', JSON.stringify(data, this.getCircularReplacer()));
  }

  public getAccountRiskPercentageForTradeId(tradeId: number): number | null {
    const accountRisksStr = this.get('accountRisks');
    if (!accountRisksStr) {
      return null;
    }

    let accountRisksObj: number[];

    try {
      accountRisksObj = JSON.parse(accountRisksStr);
    } catch (e) {
      console.error('error while parsing account risks: ', e);
      return null;
    }

    return accountRisksObj[tradeId];
  }

  public saveAccountRiskPercentageForTradeId(tradeId: number, accountRiskPercentage: number): void {
    const accountRisksStr = this.get('accountRisks');
    if (accountRisksStr) {

      let accountRisksObj: number[];

      try {
        accountRisksObj = JSON.parse(accountRisksStr);
      } catch (e) {
        console.error('error while parsing account risks: ', e);
        return;
      }

      accountRisksObj[tradeId] = accountRiskPercentage;
      this.save('accountRisks', JSON.stringify(accountRisksObj));
    } else {
      const accountRisks = {};
      accountRisks[tradeId] = accountRiskPercentage;
      this.save('accountRisks', JSON.stringify(accountRisks));
    }
  }

  public deleteAccountRiskPercentageForTradeId(tradeId: number) {
    const accountRisksStr = this.get('accountRisks');
    if (accountRisksStr) {
      let accountRisksObj: any[];
      try {
        accountRisksObj = JSON.parse(accountRisksStr);
      } catch (e) {
        console.error('error while parsing account risks: ', e);
        return;
      }

      accountRisksObj[tradeId] = undefined;
      this.save('accountRisks', JSON.stringify(accountRisksObj));
    }
  }

  public addIgnoreNotification(notification: Notification) {
    const nots: string = this.get(LocalStorageService.ignoredNotificationsKey);

    if (nots) {
      const data: number[] = JSON.parse(nots);

      if (data.indexOf(notification.Id) < 0) {
        data.push(notification.Id);
      }

      this.save(LocalStorageService.ignoredNotificationsKey, JSON.stringify(data));
    } else {
      this.save(LocalStorageService.ignoredNotificationsKey, JSON.stringify([notification.Id]));
    }
  }

  public getIgnoredNotificationIds(): number[] {
    const nots = this.get(LocalStorageService.ignoredNotificationsKey);

    if (!nots) {
      return [];
    }

    return <number[]>JSON.parse(nots);
  }

  private convertSettings(key: number, settings: Settings) {
    const temp = {};

    Object.keys(settings).forEach( key => {
      if (key === '_favoriteSymbols') {
        temp['_favoriteSymbols'] = Array.from(settings[key]);
      } else { temp[key] = settings[key]; }
    });

    return {
      key: key,
      value: temp
    };
  }

  public removeAll(): void {
    this.remove('Session');
    this.clearRecordOfOpenTemplates();
  }

  public static get SessionUpdated(): ISmartObserver<void> {
    return LocalStorageService.sessionUpdated;
  }

  public getViewToOpen(): string {
    return this.get(LocalStorageService.viewToOpenKey);
  }

  public setViewToOpen(viewName: string): void {
    this.save(LocalStorageService.viewToOpenKey, viewName);
  }

  public clearViewToOpen(): void {
    this.remove(LocalStorageService.viewToOpenKey);
  }

  public getSumAllTemplateIsOpen(): string {
    return this.get(LocalStorageService.sumAllTemplateIsOpen);
  }

  public setSumAllTemplateIsOpen(count: number): void {
    this.save(LocalStorageService.sumAllTemplateIsOpen, `${count}`);
  }

  public clearSumAllTemplateIsOpen(): void {
    this.remove(LocalStorageService.sumAllTemplateIsOpen);
  }

  public getTemplatesToDisplay(): string {
    return this.get(LocalStorageService.templatesToDisplay);
  }

  public setTemplatesToDisplay(arrayTemplatesToDisplay: string): void {
    this.save(LocalStorageService.templatesToDisplay, arrayTemplatesToDisplay);
  }

  public clearTemplatesToDisplay(): void {
    this.remove(LocalStorageService.templatesToDisplay);
  }

  public getIsOpenOnlyOneWindow(): string {
    return this.get(LocalStorageService.openOnlyOneWindow);
  }

  public setIsOpenOnlyOneWindow(): void {
    this.save(LocalStorageService.openOnlyOneWindow, '1');
  }

  public removeIsOpenOnlyOneWindow(): void {
    this.remove(LocalStorageService.openOnlyOneWindow);
  }

  public getSavedViewData(key: string = LocalStorageService.savedViewData): string {
    return this.get(key);
  }

  public setSavedViewData(data: string, key: string = LocalStorageService.savedViewData): void {
    this.save(key, data);
  }

  public getHashSumMainView(): number {
    return parseFloat(this.get(LocalStorageService.hashSumMainView));
  }

  public setHashSumMainView(data: number) {
    this.save(LocalStorageService.hashSumMainView, data.toString());
  }

  public clearHashSumMainView(): void {
    this.remove(LocalStorageService.hashSumMainView);
  }

  // методы для обработки адаптивной настройки категории групп символов
  public getSymbolSettingsAdaptiveStorage(traderName: string) {
    const dict = this.getSymbolSettingsAdaptive();
    if (dict && dict[traderName]) {
      return dict[traderName];
    } else {
      return undefined;
    }
  }

  public saveSymbolSettingsAdaptiveStorage(traderName: string, settings) {
    let dict = this.getSymbolSettingsAdaptive();
    if (!dict) {
      dict = {};
    }
    dict[traderName] = settings;
    this.saveSymbolSettingsAdaptive(dict);
  }

  private getSymbolSettingsAdaptive() {
    const json = this.get('SymbolSettingsAdaptive');
    if (!json) {
      return undefined;
    }
    const dict = JSON.parse(json);
    return dict;
  }

  private saveSymbolSettingsAdaptive(dict: object) {
    const json = JSON.stringify(dict);
    this.save('SymbolSettingsAdaptive', json);
  }

  private clearRecordOfOpenTemplates(): void {
    let sumOpenTemplates = Number(this.getSumAllTemplateIsOpen());
    if (sumOpenTemplates !== null) {
      if (sumOpenTemplates === 0) {
        this.clearSumAllTemplateIsOpen();
      } else {
        this.setSumAllTemplateIsOpen(--sumOpenTemplates);
      }
    }

  }

  public getUsersGeoData(): UsersGeoData|undefined {
    const usersGeoData = this.get('usersGeoData');

    if (usersGeoData === null) {
      return undefined;
    }

    return JSON.parse(usersGeoData);
  }

  public saveUsersGeoData(data: UsersGeoData): void {
    this.save('usersGeoData', JSON.stringify(data));
  }

  public removeUserGeoData(): void {
    this.remove('usersGeoData');
  }
}
