/*
 * ATTENTION: This file was generated with the SignalRCodeGenerator.
 * DO NOT make changes to this file as those anyway will be erased after build.
 */

import { ProtoV2MessageBase } from './proto-v2-message-base';
import { Answer_Error } from './classes';

export class Message_CustomSession extends ProtoV2MessageBase {
    public static GetMessageType(): string {return  "Message_CustomSession"};
    public SessionID: string  = null;
    public MessageSignature: string  = null;

    constructor() {
        super();
    }
}

export class Message_CustomCommand extends Message_CustomSession {
    public static GetMessageType(): string {return  "Message_CustomCommand"};
    public CommandID: number  = 0;

    constructor() {
        super();
    }
}

export class Notification_ItemTick extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  1};
    public static GetMessageType(): string {return  "Notification_ItemTick"};
    public Bid: number  = 0;
    public Ask: number  = 0;
    public ItemID: number  = 0;
    public ServerTime: Date  = new Date();
    public MarketDepthAsk: number  = 0;
    public MarketDepthBid: number  = 0;
    public PriceToPointMul: number  = 0;
    public ConversionToUSD: number  = 0;

    constructor() {
        super();
        this.BinaryID = 1;
    }
}

export class Notification_ItemHistory_Item extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  2};
    public static GetMessageType(): string {return  "Notification_ItemHistory_Item"};
    public OpenPriceOffset: number  = 0;
    public ClosePriceOffset: number  = 0;
    public HighPriceOffset: number  = 0;
    public LowPriceOffset: number  = 0;
    public OpenDateOffset: number  = 0;
    public ItemVolumeOffset: number  = 0;
    public CloseSpread: number  = 0;
    public CloseConversionToUSD: number  = 0;

    constructor() {
        super();
        this.BinaryID = 2;
    }
}

export class Notification_CustomSession extends Message_CustomSession {
    public static GetBinaryID(): number {return  3};
    public static GetMessageType(): string {return  "Notification_CustomSession"};
    constructor() {
        super();
        this.BinaryID = 3;
    }
}

export class Ping extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  4};
    public static GetMessageType(): string {return  "Ping"};
    public SentTime: Date  = new Date();

    constructor() {
        super();
        this.BinaryID = 4;
    }
}

export class Command_CustomSession extends Message_CustomCommand {
    public static GetBinaryID(): number {return  5};
    public static GetMessageType(): string {return  "Command_CustomSession"};
    constructor() {
        super();
        this.BinaryID = 5;
    }
}

export class Command_Login extends Command_CustomSession {
    public static GetBinaryID(): number {return  6};
    public static GetMessageType(): string {return  "Command_Login"};
    public LoginName: string  = null;
    public PasswordHash: string  = null;
    public SoftwareVersion: string  = null;
    public AppName: string  = null;
    public FileVersion: string  = null;
    public AssignConnectionContext: boolean  = false;
    public IPAddress: number  = 0;
    public isFixConnection: boolean  = false;
    public TokenSignature: string  = null;
    public LinkID: string  = null;
    public ConnectionPointPool: number  = 0;
    public ClientData: string  = null;

    constructor() {
        super();
        this.BinaryID = 6;
    }
}

export class Command_ConnectionPointLogin extends Command_Login {
    public static GetBinaryID(): number {return  7};
    public static GetMessageType(): string {return  "Command_ConnectionPointLogin"};
    constructor() {
        super();
        this.BinaryID = 7;
    }
}

export class Command_GetServerOption extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  8};
    public static GetMessageType(): string {return  "Command_GetServerOption"};
    constructor() {
        super();
        this.BinaryID = 8;
    }
}

export class Command_Logout extends Command_CustomSession {
    public static GetBinaryID(): number {return  9};
    public static GetMessageType(): string {return  "Command_Logout"};
    public AppName: string  = null;

    constructor() {
        super();
        this.BinaryID = 9;
    }
}

export class Command_SubscribeItemTick extends Command_CustomSession {
    public static GetBinaryID(): number {return  12};
    public static GetMessageType(): string {return  "Command_SubscribeItemTick"};
    public ItemID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 12;
    }
}

export class Command_SubscribeItemHistory extends Command_CustomSession {
    public static GetBinaryID(): number {return  13};
    public static GetMessageType(): string {return  "Command_SubscribeItemHistory"};
    public ItemID: number  = 0;
    public ZoomType: number  = 0;
    public OpenDate: Date  = new Date();

    constructor() {
        super();
        this.BinaryID = 13;
    }
}

export class Command_UnsubscribeItem extends Command_CustomSession {
    public static GetBinaryID(): number {return  14};
    public static GetMessageType(): string {return  "Command_UnsubscribeItem"};
    public ItemID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 14;
    }
}

export class Command_CustomTicket extends Command_CustomSession {
    public static GetBinaryID(): number {return  15};
    public static GetMessageType(): string {return  "Command_CustomTicket"};
    public ClientOrderID: string  = null;

    constructor() {
        super();
        this.BinaryID = 15;
    }
}

export class Command_TicketClose extends Command_CustomTicket {
    public static GetBinaryID(): number {return  16};
    public static GetMessageType(): string {return  "Command_TicketClose"};
    public OrderID: number  = 0;
    public LotsCount: number  = 0;
    public RequestPrice: number  = 0;
    public Deviation: number  = 0;
    public Comment: string  = null;
    public FillOrKill: boolean  = false;
    public Volume: number  = 0;

    constructor() {
        super();
        this.BinaryID = 16;
    }
}

export class Command_TicketDelete extends Command_CustomTicket {
    public static GetBinaryID(): number {return  17};
    public static GetMessageType(): string {return  "Command_TicketDelete"};
    public OrderID: number  = 0;
    public Comment: string  = null;

    constructor() {
        super();
        this.BinaryID = 17;
    }
}

export class Command_TicketOpen extends Command_CustomTicket {
    public static GetBinaryID(): number {return  18};
    public static GetMessageType(): string {return  "Command_TicketOpen"};
    public ItemID: number  = 0;
    public LotsCount: number  = 0;
    public OrderType: number  = 0;
    public OpenPrice: number  = 0;
    public StopLoss: number  = 0;
    public TakeProfit: number  = 0;
    public TrailingStop: number  = 0;
    public RequestPrice: number  = 0;
    public Deviation: number  = 0;
    public ExpirationDate: Date  = new Date();
    public Comment: string  = null;
    public FillOrKill: boolean  = false;
    public Volume: number  = 0;

    constructor() {
        super();
        this.BinaryID = 18;
    }
}

export class Command_TicketOpen2 extends Command_TicketOpen {
    public static GetBinaryID(): number {return  19};
    public static GetMessageType(): string {return  "Command_TicketOpen2"};
    constructor() {
        super();
        this.BinaryID = 19;
    }
}

export class Command_TicketUpdateStops extends Command_CustomTicket {
    public static GetBinaryID(): number {return  20};
    public static GetMessageType(): string {return  "Command_TicketUpdateStops"};
    public OrderID: number  = 0;
    public StopLoss: number  = 0;
    public TakeProfit: number  = 0;
    public TrailingStop: number  = 0;
    public ActualDealType: number  = 0;
    public Comment: string  = null;

    constructor() {
        super();
        this.BinaryID = 20;
    }
}

export class Command_GetOrderStates extends Command_CustomSession {
    public static GetBinaryID(): number {return  21};
    public static GetMessageType(): string {return  "Command_GetOrderStates"};
    constructor() {
        super();
        this.BinaryID = 21;
    }
}

export class Answer_CustomSession extends Message_CustomCommand {
    public static GetBinaryID(): number {return  22};
    public static GetMessageType(): string {return  "Answer_CustomSession"};
    constructor() {
        super();
        this.BinaryID = 22;
    }
}

export class Answer_LoginSuccess extends Answer_CustomSession {
    public static GetBinaryID(): number {return  23};
    public static GetMessageType(): string {return  "Answer_LoginSuccess"};
    public LoginName: string  = null;
    public PasswordHash: string  = null;
    public EncryptedLoginName: string  = null;
    public ConnectionString: string  = null;
    public TraderID: number  = 0;
    public GroupID: number  = 0;
    public LoginPwdMustChanged: boolean  = false;
    public PasswordValidationStrategy: string  = null;
    public AlgoSubscriptionLevel: number  = 0;
    public AlgoSubscriptionExpirationDate: Date  = new Date();
    public IsInvestorAccount: boolean  = false;
    public InvestedPersonalAccountId: number  = 0;
    public TradeGroupName: string  = null;
    public FirstName: string  = null;
    public MiddleName: string  = null;
    public LastName: string  = null;
    public IsDeposited: boolean  = false;
    public IsSignedAgreement: boolean  = false;
    public ExternalAccountID: string  = null;
    public IsNakedSellAllowed: boolean  = false;
    public MinFirst: number  = 0;
    public MinNext: number  = 0;
    public AccessToken: string  = null;
    public IsReadOnly: boolean  = false;
    public IsChallengeAccount: boolean  = false;
    public WithdrawalLimit: number  = 0;
    public FXServerTime: Date  = new Date();
    public LotTypeName: string  = null;
    public LotDelimeter: number  = 0;

    constructor() {
        super();
        this.BinaryID = 23;
    }
}

export class Answer_ServerOption extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  24};
    public static GetMessageType(): string {return  "Answer_ServerOption"};
    public ServerTime: Date  = new Date();
    public ServerTimeout: number  = 0;
    public SessionTimeOut: number  = 0;
    public SocketTimeOut: number  = 0;
    public ServerTimeZoneID: string  = null;
    public ServerTimeZoneDisplayName: string  = null;
    public ServerTimeZoneData: number[] = [] ;
    public TradeDayStartTime: string  = null;
    public TradeDayStartTimeZoneID: string  = null;
    public TradeDayStartTimeZoneDisplayName: string  = null;
    public TradeDayStartData: number[] = [] ;
    public EnableSlippage: boolean  = false;
    public EnableDailyFX: boolean  = false;
    public EnableAlgo: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 24;
    }
}

export class Answer_CustomSessionError extends Answer_CustomSession {
    public static GetBinaryID(): number {return  25};
    public static GetMessageType(): string {return  "Answer_CustomSessionError"};
    public Error: Answer_Error  = null;

    constructor() {
        super();
        this.BinaryID = 25;
    }
}

export class Answer_GetItems_Item extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  28};
    public static GetMessageType(): string {return  "Answer_GetItems_Item"};
    public ItemID: number  = 0;
    public ItemName: string  = null;
    public LongName: string  = null;
    public SourceSymbolName: string  = null;
    public QuoteSource: string  = null;
    public QuotationType: number  = 0;
    public DecimalPlaces: number  = 0;
    public PipSize: number  = 0;
    public Spread: number  = 0;
    public InitialMarginRate: number  = 0;
    public MaintenanceMarginRate: number  = 0;
    public MarginCalcType: number  = 0;
    public ProfitCalcType: number  = 0;
    public ItemStatus: number  = 0;
    public Internal: boolean  = false;
    public RolloverBuy: number  = 0;
    public RolloverSell: number  = 0;
    public BCConvertSymbolID: number  = 0;
    public CCConvertSymbolID: number  = 0;
    public RolloverCalcType: number  = 0;
    public SymbolCategory: number  = 0;
    public SymbolCategoryName: string  = null;
    public BaseCurrency: string  = null;
    public CounterCurrency: string  = null;
    public MinimumSpread: number  = 0;
    public EnableStandard: boolean  = false;
    public EnableMini: boolean  = false;
    public EnableMicro: boolean  = false;
    public ContractSize: number  = 0;
    public MaxVolume: number  = 0;
    public MinVolume: number  = 0;
    public TradingStep: number  = 0;
    public TickSize: number  = 0;
    public TickPrice: number  = 0;
    public Percentage: number  = 0;
    public SortOrder: number  = 0;
    public ExternalName: string  = null;
    public ExternalCode: number  = 0;
    public Comment: string  = null;
    public IsActive: boolean  = false;
    public TransactionFee: number  = 0;
    public MinTransactionFee: number  = 0;

    constructor() {
        super();
        this.BinaryID = 28;
    }
}

export class Answer_GetItems extends Answer_CustomSession {
    public static GetBinaryID(): number {return  29};
    public static GetMessageType(): string {return  "Answer_GetItems"};
    public Items: Answer_GetItems_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 29;
    }
}

export class Answer_Version extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  30};
    public static GetMessageType(): string {return  "Answer_Version"};
    public Version: string  = null;

    constructor() {
        super();
        this.BinaryID = 30;
    }
}

export class Answer_Logout extends Answer_CustomSession {
    public static GetBinaryID(): number {return  31};
    public static GetMessageType(): string {return  "Answer_Logout"};
    public Error: Answer_Error  = null;

    constructor() {
        super();
        this.BinaryID = 31;
    }
}

export class Answer_SimpleCommandSuccess extends Answer_CustomSession {
    public static GetBinaryID(): number {return  32};
    public static GetMessageType(): string {return  "Answer_SimpleCommandSuccess"};
    constructor() {
        super();
        this.BinaryID = 32;
    }
}

export class Answer_MailMessageHeader extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  33};
    public static GetMessageType(): string {return  "Answer_MailMessageHeader"};
    public MailID: number  = 0;
    public UserSenderID: number  = 0;
    public SenderLoginName: string  = null;
    public SendTime: Date  = new Date();
    public Subject: string  = null;
    public IsNews: boolean  = false;
    public IsRead: boolean  = false;
    public IsDeleted: boolean  = false;
    public From: string  = null;
    public SessionID: string  = null;
    public ServerTime: Date  = new Date();

    constructor() {
        super();
        this.BinaryID = 33;
    }
}

export class Answer_TicketNumber extends Answer_CustomSession {
    public static GetBinaryID(): number {return  34};
    public static GetMessageType(): string {return  "Answer_TicketNumber"};
    public DealID: number  = 0;
    public IsDealCreated: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 34;
    }
}

export class Answer_TicketAccept extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  35};
    public static GetMessageType(): string {return  "Answer_TicketAccept"};
    public DealID: number  = 0;
    public Result: number  = 0;
    public SessionID: string  = null;

    constructor() {
        super();
        this.BinaryID = 35;
    }
}

export class Notification_TicketDecline extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  36};
    public static GetMessageType(): string {return  "Notification_TicketDecline"};
    public DealID: number  = 0;
    public Result: number  = 0;
    public SessionID: string  = null;
    public Message: string  = null;
    public IsDealCreated: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 36;
    }
}

export class Message_AccountingDocument extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  37};
    public static GetMessageType(): string {return  "Message_AccountingDocument"};
    public DocumentID: number  = 0;
    public IncomeItemID: number  = 0;
    public Amount: number  = 0;

    constructor() {
        super();
        this.BinaryID = 37;
    }
}

export class Message_OrderState extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  38};
    public static GetMessageType(): string {return  "Message_OrderState"};
    public OrderID: number  = 0;
    public StateID: number  = 0;
    public TraderID: number  = 0;
    public SymbolID: number  = 0;
    public OrderTypeID: number  = 0;
    public Volume: number  = 0;
    public OpenPrice: number  = 0;
    public StopLoss: number  = 0;
    public TakeProfit: number  = 0;
    public TrailingStop: number  = 0;
    public TrailingStopValue: number  = 0;
    public CommandVolume: number  = 0;
    public CommandPrice: number  = 0;
    public CommandTime: Date  = new Date();
    public CommandTypeID: number  = 0;
    public CommandOriginID: number  = 0;
    public CommandSenderID: number  = 0;
    public Margin: number  = 0;
    public CommandMargin: number  = 0;
    public Comment: string  = null;
    public ExpirationDate: Date  = new Date();
    public ClientOrderID: string  = null;
    public DealID: number  = 0;
    public CommandVolumeUSD: number  = 0;
    public CommandVolumeAC: number  = 0;
    public VolumeUSD: number  = 0;
    public VolumeAC: number  = 0;

    public AccountingDocuments: Message_AccountingDocument[] = [];

    constructor() {
        super();
        this.BinaryID = 38;
    }
}

export class Answer_GetOrderStates extends Answer_CustomSession {
    public static GetBinaryID(): number {return  39};
    public static GetMessageType(): string {return  "Answer_GetOrderStates"};
    public OrderStates: Message_OrderState[] = [];

    constructor() {
        super();
        this.BinaryID = 39;
    }
}

export class Notification_ItemHistory extends Notification_CustomSession {
    public static GetBinaryID(): number {return  40};
    public static GetMessageType(): string {return  "Notification_ItemHistory"};
    public ServerTime: Date  = new Date();
    public ItemID: number  = 0;
    public ZoomType: number  = 0;
    public InitialOpenDate: Date  = new Date();
    public InitialVolume: number  = 0;
    public InitialOpenPrice: number  = 0;

    public ItemHistoryItems: Notification_ItemHistory_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 40;
    }
}

export class Command_GetItems extends Command_CustomSession {
    public static GetBinaryID(): number {return  41};
    public static GetMessageType(): string {return  "Command_GetItems"};
    constructor() {
        super();
        this.BinaryID = 41;
    }
}

export class Notification_OrderStates extends Notification_CustomSession {
    public static GetBinaryID(): number {return  44};
    public static GetMessageType(): string {return  "Notification_OrderStates"};
    public OrderStates: Message_OrderState[] = [];

    constructor() {
        super();
        this.BinaryID = 44;
    }
}

export class Command_GetBalance extends Command_CustomSession {
    public static GetBinaryID(): number {return  45};
    public static GetMessageType(): string {return  "Command_GetBalance"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 45;
    }
}

export class Answer_GetBalance extends Answer_CustomSession {
    public static GetBinaryID(): number {return  46};
    public static GetMessageType(): string {return  "Answer_GetBalance"};
    public Balance: number  = 0;
    public CreditIssued: number  = 0;

    constructor() {
        super();
        this.BinaryID = 46;
    }
}

export class Notification_Balance extends Notification_CustomSession {
    public static GetBinaryID(): number {return  47};
    public static GetMessageType(): string {return  "Notification_Balance"};
    public NotificationID: number  = 0;
    public ServerTime: Date  = new Date();
    public TraderID: number  = 0;
    public Balance: number  = 0;
    public CreditIssued: number  = 0;

    constructor() {
        super();
        this.BinaryID = 47;
    }
}

export class Command_UnsubscribeItemHistory extends Command_CustomSession {
    public static GetBinaryID(): number {return  48};
    public static GetMessageType(): string {return  "Command_UnsubscribeItemHistory"};
    public ItemID: number  = 0;
    public ZoomType: number  = 0;

    constructor() {
        super();
        this.BinaryID = 48;
    }
}

export class Answer_UnsubscribeItemHistory extends Answer_CustomSession {
    public static GetBinaryID(): number {return  49};
    public static GetMessageType(): string {return  "Answer_UnsubscribeItemHistory"};
    constructor() {
        super();
        this.BinaryID = 49;
    }
}

export class Command_TicketUpdatePendingOrder extends Command_CustomTicket {
    public static GetBinaryID(): number {return  50};
    public static GetMessageType(): string {return  "Command_TicketUpdatePendingOrder"};
    public OrderID: number  = 0;
    public OrderType: number  = 0;
    public StopLoss: number  = 0;
    public TakeProfit: number  = 0;
    public TrailingStop: number  = 0;
    public Volume: number  = 0;
    public VolumeDecimalPlaces: number  = 0;
    public OpenPrice: number  = 0;
    public ExpirationDate: Date  = new Date();
    public ActualDealType: number  = 0;
    public Comment: string  = null;

    constructor() {
        super();
        this.BinaryID = 50;
    }
}

export class Command_GetTraderInfo extends Command_CustomSession {
    public static GetBinaryID(): number {return  51};
    public static GetMessageType(): string {return  "Command_GetTraderInfo"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 51;
    }
}

export class Answer_GetTraderInfo extends Answer_CustomSession {
    public static GetBinaryID(): number {return  52};
    public static GetMessageType(): string {return  "Answer_GetTraderInfo"};
    public MarginCall: number  = 0;
    public AccountStatementUrl: string  = null;
    public AccountHistoryUrl: string  = null;
    public TradeHistoryUrl: string  = null;
    public SecurityReportUrl: string  = null;
    public BackOfficeUrl: string  = null;
    public BackOfficeIndexPageUrl: string  = null;
    public CashDepositReportUrl: string  = null;
    public CashWithdrawalReportUrl: string  = null;
    public TraderCurrency_ISOCurrencyID: number  = 0;
    public TraderCurrency_ISOCurrencyName: string  = null;
    public TraderCurrency_RateType: number  = 0;
    public TraderCurrency_RateSymbolID: number  = 0;
    public TraderCurrency_RateBid: number  = 0;
    public TraderCurrency_RateAsk: number  = 0;
    public XMLExtension: string  = null;
    public AdvertisementOnCloseUrl: string  = null;
    public IsTradingDisabled: boolean  = false;
    public SubscribeAlgoUrl: string  = null;
    public TwitterAlgoUrl: string  = null;
    public FacebookAlgoUrl: string  = null;
    public ShowMostPopularStrategies: boolean  = false;
    public ShowAlgoSubscriptions: boolean  = false;
    public UseVolumeTrading: boolean  = false;
    public IsInvestorAccount: boolean  = false;
    public InvestedPersonalAccountId: number  = 0;

    constructor() {
        super();
        this.BinaryID = 52;
    }
}

export class CmdDealer_GetOrderStates extends Command_CustomSession {
    public static GetBinaryID(): number {return  58};
    public static GetMessageType(): string {return  "CmdDealer_GetOrderStates"};
    constructor() {
        super();
        this.BinaryID = 58;
    }
}

export class Command_MailReceived extends Message_CustomSession {
    public static GetBinaryID(): number {return  59};
    public static GetMessageType(): string {return  "Command_MailReceived"};
    public MailID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 59;
    }
}

export class Command_GetMailMessageHeaders extends Command_CustomSession {
    public static GetBinaryID(): number {return  60};
    public static GetMessageType(): string {return  "Command_GetMailMessageHeaders"};
    public TraderID: number  = 0;
    public LastReceivedMailID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 60;
    }
}

export class Command_GetMailMessageBody extends Command_CustomSession {
    public static GetBinaryID(): number {return  61};
    public static GetMessageType(): string {return  "Command_GetMailMessageBody"};
    public MailID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 61;
    }
}

export class Notification_Mail extends Notification_CustomSession {
    public static GetBinaryID(): number {return  62};
    public static GetMessageType(): string {return  "Notification_Mail"};
    public MailMessages: Answer_MailMessageHeader[] = [];

    constructor() {
        super();
        this.BinaryID = 62;
    }
}

export class Answer_GetMailMessageHeaders extends Answer_CustomSession {
    public static GetBinaryID(): number {return  63};
    public static GetMessageType(): string {return  "Answer_GetMailMessageHeaders"};
    constructor() {
        super();
        this.BinaryID = 63;
    }
}

export class Answer_GetMailMessageBody extends Answer_CustomSession {
    public static GetBinaryID(): number {return  64};
    public static GetMessageType(): string {return  "Answer_GetMailMessageBody"};
    constructor() {
        super();
        this.BinaryID = 64;
    }
}

export class Notification_MailMessageBody extends Notification_CustomSession {
    public static GetBinaryID(): number {return  65};
    public static GetMessageType(): string {return  "Notification_MailMessageBody"};
    public MailID: number  = 0;
    public MessageBody: string  = null;

    constructor() {
        super();
        this.BinaryID = 65;
    }
}

export class Answer_SubscribeItemTick extends Command_CustomSession {
    public static GetBinaryID(): number {return  66};
    public static GetMessageType(): string {return  "Answer_SubscribeItemTick"};
    public Error: Answer_Error  = null;

    constructor() {
        super();
        this.BinaryID = 66;
    }
}

export class Answer_SubscribeItemHistory extends Command_CustomSession {
    public static GetBinaryID(): number {return  67};
    public static GetMessageType(): string {return  "Answer_SubscribeItemHistory"};
    public Error: Answer_Error  = null;

    constructor() {
        super();
        this.BinaryID = 67;
    }
}

export class Command_CreateCategory extends Command_CustomSession {
    public static GetBinaryID(): number {return  68};
    public static GetMessageType(): string {return  "Command_CreateCategory"};
    public CategoryName: string  = null;
    public CategoryDescription: string  = null;
    public OrderProcessingTypeID: number  = 0;
    public MarketProviderName: string  = null;
    public RiskManagementTypeID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 68;
    }
}

export class Answer_CreateCategory extends Answer_CustomSessionError {
    public static GetBinaryID(): number {return  69};
    public static GetMessageType(): string {return  "Answer_CreateCategory"};
    public CategoryID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 69;
    }
}

export class Command_UpdateCategory extends Command_CustomSession {
    public static GetBinaryID(): number {return  70};
    public static GetMessageType(): string {return  "Command_UpdateCategory"};
    public CategoryID: number  = 0;
    public CategoryName: string  = null;
    public CategoryDescription: string  = null;
    public OrderProcessingTypeID: number  = 0;
    public MarketProviderName: string  = null;
    public RiskManagementTypeID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 70;
    }
}

export class Answer_UpdateCategory extends Answer_CustomSessionError {
    public static GetBinaryID(): number {return  71};
    public static GetMessageType(): string {return  "Answer_UpdateCategory"};
    constructor() {
        super();
        this.BinaryID = 71;
    }
}

export class Command_GetAllCategories extends Command_CustomSession {
    public static GetBinaryID(): number {return  72};
    public static GetMessageType(): string {return  "Command_GetAllCategories"};
    constructor() {
        super();
        this.BinaryID = 72;
    }
}

export class Message_Category extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  73};
    public static GetMessageType(): string {return  "Message_Category"};
    public CategoryID: number  = 0;
    public CategoryName: string  = null;
    public CategoryDescription: string  = null;
    public OrderProcessingTypeID: number  = 0;
    public MarketProviderName: string  = null;
    public RiskManagementTypeID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 73;
    }
}

export class Answer_GetAllCategories extends Answer_CustomSession {
    public static GetBinaryID(): number {return  74};
    public static GetMessageType(): string {return  "Answer_GetAllCategories"};
    public Categories: Message_Category[] = [];

    constructor() {
        super();
        this.BinaryID = 74;
    }
}

export class Command_AddTraderToCategory extends Command_CustomSession {
    public static GetBinaryID(): number {return  75};
    public static GetMessageType(): string {return  "Command_AddTraderToCategory"};
    public TraderID: number  = 0;
    public CategoryID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 75;
    }
}

export class Answer_AddTraderToCategory extends Answer_CustomSessionError {
    public static GetBinaryID(): number {return  76};
    public static GetMessageType(): string {return  "Answer_AddTraderToCategory"};
    constructor() {
        super();
        this.BinaryID = 76;
    }
}

export class Command_RemoveTraderFromCategory extends Command_CustomSession {
    public static GetBinaryID(): number {return  77};
    public static GetMessageType(): string {return  "Command_RemoveTraderFromCategory"};
    public TraderID: number  = 0;
    public CategoryID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 77;
    }
}

export class Answer_RemoveTraderFromCategory extends Answer_CustomSessionError {
    public static GetBinaryID(): number {return  78};
    public static GetMessageType(): string {return  "Answer_RemoveTraderFromCategory"};
    constructor() {
        super();
        this.BinaryID = 78;
    }
}

export class Command_GetTraderCategories extends Command_CustomSession {
    public static GetBinaryID(): number {return  79};
    public static GetMessageType(): string {return  "Command_GetTraderCategories"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 79;
    }
}

export class Answer_GetTraderCategories extends Answer_CustomSession {
    public static GetBinaryID(): number {return  80};
    public static GetMessageType(): string {return  "Answer_GetTraderCategories"};
    public Categories: Message_Category[] = [];

    constructor() {
        super();
        this.BinaryID = 80;
    }
}

export class Notification_CategoryUpdated extends Notification_CustomSession {
    public static GetBinaryID(): number {return  81};
    public static GetMessageType(): string {return  "Notification_CategoryUpdated"};
    public CategoryID: number  = 0;
    public CategoryName: string  = null;
    public CategoryDescription: string  = null;
    public OrderProcessingTypeID: number  = 0;
    public MarketProviderName: string  = null;
    public RiskManagementTypeID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 81;
    }
}

export class Notification_TraderCategoryUpdated extends Notification_CustomSession {
    public static GetBinaryID(): number {return  82};
    public static GetMessageType(): string {return  "Notification_TraderCategoryUpdated"};
    public TraderID: number  = 0;

    public Categories: Message_Category[] = [];

    constructor() {
        super();
        this.BinaryID = 82;
    }
}

export class Command_GetMarginStatus extends Command_CustomSession {
    public static GetBinaryID(): number {return  83};
    public static GetMessageType(): string {return  "Command_GetMarginStatus"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 83;
    }
}

export class Answer_GetMarginStatus extends Answer_CustomSession {
    public static GetBinaryID(): number {return  84};
    public static GetMessageType(): string {return  "Answer_GetMarginStatus"};
    public TraderID: number  = 0;
    public MarginStatus: number  = 0;
    public LiquidationTime: Date  = new Date();

    constructor() {
        super();
        this.BinaryID = 84;
    }
}

export class Notification_MarginStatus extends Notification_CustomSession {
    public static GetBinaryID(): number {return  85};
    public static GetMessageType(): string {return  "Notification_MarginStatus"};
    public TraderID: number  = 0;
    public MarginStatus: number  = 0;
    public LiquidationTime: Date  = new Date();
    public NotificationID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 85;
    }
}

export class Notification_Disconnect extends Notification_CustomSession {
    public static GetBinaryID(): number {return  86};
    public static GetMessageType(): string {return  "Notification_Disconnect"};
    public DisconnectReason: Answer_Error  = null;

    constructor() {
        super();
        this.BinaryID = 86;
    }
}

export class FXCMData extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  87};
    public static GetMessageType(): string {return  "FXCMData"};
    public Data: string  = null;

    constructor() {
        super();
        this.BinaryID = 87;
    }
}

export class Command_DisconnectSessionConnection extends Message_CustomSession {
    public static GetBinaryID(): number {return  88};
    public static GetMessageType(): string {return  "Command_DisconnectSessionConnection"};
    constructor() {
        super();
        this.BinaryID = 88;
    }
}

export class Command_ChangeLoginPassword extends Command_CustomSession {
    public static GetBinaryID(): number {return  89};
    public static GetMessageType(): string {return  "Command_ChangeLoginPassword"};
    public CurrentPassword: string  = null;
    public NewPassword: string  = null;

    constructor() {
        super();
        this.BinaryID = 89;
    }
}

export class Answer_ChangeLoginPasswordSuccess extends Answer_SimpleCommandSuccess {
    public static GetBinaryID(): number {return  90};
    public static GetMessageType(): string {return  "Answer_ChangeLoginPasswordSuccess"};
    constructor() {
        super();
        this.BinaryID = 90;
    }
}

export class Answer_ChangeLoginPasswordError extends Answer_CustomSessionError {
    public static GetBinaryID(): number {return  91};
    public static GetMessageType(): string {return  "Answer_ChangeLoginPasswordError"};
    constructor() {
        super();
        this.BinaryID = 91;
    }
}

export class Command_GetTradeAccountInfo extends Command_CustomSession {
    public static GetBinaryID(): number {return  92};
    public static GetMessageType(): string {return  "Command_GetTradeAccountInfo"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 92;
    }
}

export class Answer_GetTradeAccountInfo extends Answer_CustomSession {
    public static GetBinaryID(): number {return  93};
    public static GetMessageType(): string {return  "Answer_GetTradeAccountInfo"};
    public Balance: number  = 0;
    public MaintenanceMargin: number  = 0;
    public ISOCurrencyName: string  = null;

    constructor() {
        super();
        this.BinaryID = 93;
    }
}

export class Command_GetAllTradeGroups extends Command_CustomSession {
    public static GetBinaryID(): number {return  94};
    public static GetMessageType(): string {return  "Command_GetAllTradeGroups"};
    constructor() {
        super();
        this.BinaryID = 94;
    }
}

export class Message_SymbolSettings extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  95};
    public static GetMessageType(): string {return  "Message_SymbolSettings"};
    public SymbolID: number  = 0;
    public InitialMarginRate: number  = 0;
    public MaintenanceMarginRate: number  = 0;
    public DecimalPlaces: number  = 0;
    public PipSize: number  = 0;
    public BidCorrection: number  = 0;
    public AskCorrection: number  = 0;
    public ProfitCalcType: number  = 0;
    public SpreadCorrectionType: number  = 0;
    public SpreadCorrectionValue: number  = 0;

    constructor() {
        super();
        this.BinaryID = 95;
    }
}

export class Message_TradeGroup extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  96};
    public static GetMessageType(): string {return  "Message_TradeGroup"};
    public TradeGroupID: number  = 0;

    public Symbols: Message_SymbolSettings[] = [];

    constructor() {
        super();
        this.BinaryID = 96;
    }
}

export class Answer_GetAllTradeGroups extends Answer_CustomSession {
    public static GetBinaryID(): number {return  97};
    public static GetMessageType(): string {return  "Answer_GetAllTradeGroups"};
    public TradeGroups: Message_TradeGroup[] = [];

    constructor() {
        super();
        this.BinaryID = 97;
    }
}

export class Notification_SymbolSettingsUpdated extends Notification_CustomSession {
    public static GetBinaryID(): number {return  98};
    public static GetMessageType(): string {return  "Notification_SymbolSettingsUpdated"};
    public TradeGroupID: number  = 0;
    public SymbolID: number  = 0;
    public InitialMarginRate: number  = 0;
    public MaintenanceMarginRate: number  = 0;
    public DecimalPlaces: number  = 0;
    public PipSize: number  = 0;
    public BidCorrection: number  = 0;
    public AskCorrection: number  = 0;
    public ProfitCalcType: number  = 0;
    public SpreadCorrectionType: number  = 0;
    public SpreadCorrectionValue: number  = 0;
    public TransactionFee: number  = 0;
    public MinTransactionFee: number  = 0;

    constructor() {
        super();
        this.BinaryID = 98;
    }
}

export class Notification_HistoryBlock extends Notification_CustomSession {
    public static GetBinaryID(): number {return  99};
    public static GetMessageType(): string {return  "Notification_HistoryBlock"};
    public BlockId: number  = 0;
    public ItemID: number  = 0;
    public ZoomType: number  = 0;
    public InitialVolume: number  = 0;
    public InitialOpenPrice: number  = 0;
    public PackedIntervals: number[] = [] ;
    public Version: number  = 0;

    constructor() {
        super();
        this.BinaryID = 99;
    }
}

export class Notification_HistoryInterval extends Notification_CustomSession {
    public static GetBinaryID(): number {return  100};
    public static GetMessageType(): string {return  "Notification_HistoryInterval"};
    public OpenPrice: number  = 0;
    public ClosePrice: number  = 0;
    public HighPrice: number  = 0;
    public LowPrice: number  = 0;
    public OpenDate: Date  = new Date();
    public Volume: number  = 0;
    public SymbolId: number  = 0;
    public ZoomType: number  = 0;

    constructor() {
        super();
        this.BinaryID = 100;
    }
}

export class Command_GetHistoryBlock extends Command_CustomSession {
    public static GetBinaryID(): number {return  101};
    public static GetMessageType(): string {return  "Command_GetHistoryBlock"};
    public BlockId: number  = 0;
    public IsRetreiveLastBlock: boolean  = false;
    public IsRetreiveFirstBlock: boolean  = false;
    public ItemID: number  = 0;
    public ZoomType: number  = 0;

    constructor() {
        super();
        this.BinaryID = 101;
    }
}

export class Answer_HistoryBlock extends Answer_CustomSession {
    public static GetBinaryID(): number {return  102};
    public static GetMessageType(): string {return  "Answer_HistoryBlock"};
    public BlockId: number  = 0;
    public ItemID: number  = 0;
    public ZoomType: number  = 0;
    public InitialVolume: number  = 0;
    public InitialOpenPrice: number  = 0;
    public Version: number  = 0;
    public PackedIntervals: number[] = [] ;

    constructor() {
        super();
        this.BinaryID = 102;
    }
}

export class Command_SubscribeEx extends Command_CustomSession {
    public static GetBinaryID(): number {return  103};
    public static GetMessageType(): string {return  "Command_SubscribeEx"};
    public ItemTick: Command_SubscribeItemTick[] = [];
    public MultiTierItemTick: Command_SubscribeMultiTierItemTick[] = [];
    public ItemHistory: Command_SubscribeItemHistory[] = [];

    constructor() {
        super();
        this.BinaryID = 103;
    }
}

export class Command_GetRecommendedStrategiesParams extends Command_CustomSession {
    public static GetBinaryID(): number {return  104};
    public static GetMessageType(): string {return  "Command_GetRecommendedStrategiesParams"};
    constructor() {
        super();
        this.BinaryID = 104;
    }
}

export class Answer_GetRecommendedStrategiesParams extends Answer_CustomSession {
    public static GetBinaryID(): number {return  105};
    public static GetMessageType(): string {return  "Answer_GetRecommendedStrategiesParams"};
    public RecommendedParameters: Answer_GetRecommendedParams_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 105;
    }
}

export class Command_SetRecommentedStrategyParams extends Command_CustomSession {
    public static GetBinaryID(): number {return  106};
    public static GetMessageType(): string {return  "Command_SetRecommentedStrategyParams"};
    public ParamsId: number  = 0;
    public StrategyId: number  = 0;
    public ParamsCrc: number  = 0;
    public AlgoTraderFormId: string  = null;
    public RequiredSubscriptionLevel: number  = 0;
    public SymbolName: string  = null;
    public IntervalHistoryId: number  = 0;
    public ParamsXml: string  = null;

    constructor() {
        super();
        this.BinaryID = 106;
    }
}

export class Notification_RecommendedStrategyParamsChanged extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  107};
    public static GetMessageType(): string {return  "Notification_RecommendedStrategyParamsChanged"};
    constructor() {
        super();
        this.BinaryID = 107;
    }
}

export class Command_StrategySignalNotify extends Command_CustomSession {
    public static GetBinaryID(): number {return  108};
    public static GetMessageType(): string {return  "Command_StrategySignalNotify"};
    public StrategyInstanceName: string  = null;
    public SymbolName: string  = null;
    public SignalDateTime: Date  = new Date();
    public SignalType: number  = 0;
    public IsSendSms: boolean  = false;
    public IsSendEmail: boolean  = false;
    public Phone: string  = null;
    public Email: string  = null;

    constructor() {
        super();
        this.BinaryID = 108;
    }
}

export class Command_StrategyLaunch extends Command_CustomSession {
    public static GetBinaryID(): number {return  109};
    public static GetMessageType(): string {return  "Command_StrategyLaunch"};
    public StrategyId: number  = 0;
    public ParamsXml: string  = null;
    public AlgoStrategyFormId: string  = null;
    public ParamsVersion: number  = 0;

    constructor() {
        super();
        this.BinaryID = 109;
    }
}

export class Notification_ItemTickDropped extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  110};
    public static GetMessageType(): string {return  "Notification_ItemTickDropped"};
    public DroppedItemTickCount: number  = 0;
    public SymbolId: number  = 0;

    constructor() {
        super();
        this.BinaryID = 110;
    }
}

export class Command_GetAlgoStrategies extends Command_CustomSession {
    public static GetBinaryID(): number {return  111};
    public static GetMessageType(): string {return  "Command_GetAlgoStrategies"};
    constructor() {
        super();
        this.BinaryID = 111;
    }
}

export class Answer_GetAlgoStrategies_Item extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  112};
    public static GetMessageType(): string {return  "Answer_GetAlgoStrategies_Item"};
    public StrategyId: number  = 0;
    public StrategyClassName: string  = null;
    public Visible: boolean  = false;
    public VisibleInSubscriptions: boolean  = false;
    public VisibleInMostPopular: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 112;
    }
}

export class Answer_GetAlgoStrategies extends Answer_CustomSession {
    public static GetBinaryID(): number {return  113};
    public static GetMessageType(): string {return  "Answer_GetAlgoStrategies"};
    public Strategies: Answer_GetAlgoStrategies_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 113;
    }
}

export class Answer_GetRecommendedParams_Item extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  114};
    public static GetMessageType(): string {return  "Answer_GetRecommendedParams_Item"};
    public ParamsId: number  = 0;
    public StrategyId: number  = 0;
    public ParamsCrc: number  = 0;
    public AlgoTraderFormId: string  = null;
    public RequiredSubscriptionLevel: number  = 0;
    public SymbolName: string  = null;
    public IntervalHistoryId: number  = 0;
    public ParamsXml: string  = null;

    constructor() {
        super();
        this.BinaryID = 114;
    }
}

export class Notification_MultiTierItemTick extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  115};
    public static GetMessageType(): string {return  "Notification_MultiTierItemTick"};
    public Price: number  = 0;
    public OldPrice: number  = 0;
    public QuoteDirection: number  = 0;
    public QuoteType: number  = 0;
    public ItemID: number  = 0;
    public ServerTime: Date  = new Date();
    public MarketDepth: number  = 0;
    public PriceToPointMul: number  = 0;

    constructor() {
        super();
        this.BinaryID = 115;
    }
}

export class Command_SubscribeMultiTierItemTick extends Command_CustomSession {
    public static GetBinaryID(): number {return  116};
    public static GetMessageType(): string {return  "Command_SubscribeMultiTierItemTick"};
    public ItemID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 116;
    }
}

export class Answer_SubscribeMultiTierItemTick extends Command_CustomSession {
    public static GetBinaryID(): number {return  117};
    public static GetMessageType(): string {return  "Answer_SubscribeMultiTierItemTick"};
    public Error: Answer_Error  = null;

    constructor() {
        super();
        this.BinaryID = 117;
    }
}

export class Command_GetAuthTokens extends Command_CustomSession {
    public static GetBinaryID(): number {return  118};
    public static GetMessageType(): string {return  "Command_GetAuthTokens"};
    constructor() {
        super();
        this.BinaryID = 118;
    }
}

export class Answer_GetAuthTokens extends Answer_CustomSessionError {
    public static GetBinaryID(): number {return  119};
    public static GetMessageType(): string {return  "Answer_GetAuthTokens"};
    public AccessToken: string  = null;
    public RefreshToken: string  = null;
    public IsInvestorAccount: boolean  = false;
    public IsDeposited: boolean  = false;
    public IsNakedSellAllowed: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 119;
    }
}

export class Command_LoginByAccessToken extends Command_CustomSession {
    public static GetBinaryID(): number {return  120};
    public static GetMessageType(): string {return  "Command_LoginByAccessToken"};
    public AccessToken: string  = null;
    public SoftwareVersion: string  = null;
    public AppName: string  = null;
    public FileVersion: string  = null;
    public AssignConnectionContext: boolean  = false;
    public IPAddress: number  = 0;
    public ClientData: string  = null;

    constructor() {
        super();
        this.BinaryID = 120;
    }
}

export class Notification_FullMarketDepth_Item extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  121};
    public static GetMessageType(): string {return  "Notification_FullMarketDepth_Item"};
    public Bid: number  = 0;
    public BidVolume: number  = 0;
    public Ask: number  = 0;
    public AskVolume: number  = 0;

    constructor() {
        super();
        this.BinaryID = 121;
    }
}

export class Notification_FullMarketDepth extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  122};
    public static GetMessageType(): string {return  "Notification_FullMarketDepth"};
    public ItemID: number  = 0;
    public PriceToPointMul: number  = 0;
    public ConversionToUSD: number  = 0;

    public LevelList: Notification_FullMarketDepth_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 122;
    }
}

export class Command_SetSettings extends Command_CustomSession {
    public static GetBinaryID(): number {return  123};
    public static GetMessageType(): string {return  "Command_SetSettings"};
    public TraderID: number  = 0;
    public AskLine: boolean  = false;
    public BidLine: boolean  = false;
    public OneClickTrading: boolean  = false;
    public OneClickAmount: number  = 0;
    public OneClickLot: number  = 0;
    public OneClickStopLoss: number  = 0;
    public OneClickStopLossUnit: number  = 0;
    public OneClickTakeProfit: number  = 0;
    public OneClickTakeProfitUnit: number  = 0;
    public OneClickTrailingStop: number  = 0;
    public UseSltpForNonOneClickOrders: boolean  = false;
    public Sound: boolean  = false;
    public TradesOnChart: boolean  = false;
    public ChartCount: number  = 0;
    public CiqLayouts: string  = null;
    public CiqPreferences: string  = null;
    public CiqDrawings: string  = null;
    public PendingOrderAccountRisk: number  = 0;
    public PendingOrderSlPips: number  = 0;
    public PendingOrderTpPips: number  = 0;
    public UseAutoTradeSizing: boolean  = false;
    public UseAdvancedVolumeSettings: boolean  = false;
    public FavoriteSymbols: string  = null;
    public ShowNotifications: boolean  = false;
    public TvLayout: string  = null;
    public TvStudies: string  = null;
    public ViewMode: number  = 0;
    public AlwaysShowDefaultView: boolean  = false;
    public LotTrading: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 123;
    }
}

export class Answer_SetSettings extends Answer_CustomSession {
    public static GetBinaryID(): number {return  124};
    public static GetMessageType(): string {return  "Answer_SetSettings"};
    public ID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 124;
    }
}

export class Command_GetSettings extends Command_CustomSession {
    public static GetBinaryID(): number {return  125};
    public static GetMessageType(): string {return  "Command_GetSettings"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 125;
    }
}

export class Answer_GetSettings extends Answer_CustomSession {
    public static GetBinaryID(): number {return  126};
    public static GetMessageType(): string {return  "Answer_GetSettings"};
    public AskLine: boolean  = false;
    public BidLine: boolean  = false;
    public OneClickTrading: boolean  = false;
    public OneClickAmount: number  = 0;
    public OneClickLot: number  = 0;
    public OneClickStopLoss: number  = 0;
    public OneClickStopLossUnit: number  = 0;
    public OneClickTakeProfit: number  = 0;
    public OneClickTakeProfitUnit: number  = 0;
    public OneClickTrailingStop: number  = 0;
    public UseSltpForNonOneClickOrders: boolean  = false;
    public Sound: boolean  = false;
    public TradesOnChart: boolean  = false;
    public ChartCount: number  = 0;
    public CiqLayouts: string  = null;
    public CiqPreferences: string  = null;
    public CiqDrawings: string  = null;
    public PendingOrderAccountRisk: number  = 0;
    public PendingOrderSlPips: number  = 0;
    public PendingOrderTpPips: number  = 0;
    public UseAutoTradeSizing: boolean  = false;
    public UseAdvancedVolumeSettings: boolean  = false;
    public FavoriteSymbols: string  = null;
    public ShowNotifications: boolean  = false;
    public TvLayout: string  = null;
    public TvStudies: string  = null;
    public ViewMode: number  = 0;
    public AlwaysShowDefaultView: boolean  = false;
    public LotTrading: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 126;
    }
}

export class Command_SubscribeFullMarketDepth extends Command_CustomSession {
    public static GetBinaryID(): number {return  127};
    public static GetMessageType(): string {return  "Command_SubscribeFullMarketDepth"};
    public ItemID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 127;
    }
}

export class Command_UnsubscribeFullMarketDepth extends Command_CustomSession {
    public static GetBinaryID(): number {return  128};
    public static GetMessageType(): string {return  "Command_UnsubscribeFullMarketDepth"};
    public ItemID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 128;
    }
}

export class Command_UnsubscribeMultiTierItemTick extends Command_CustomSession {
    public static GetBinaryID(): number {return  129};
    public static GetMessageType(): string {return  "Command_UnsubscribeMultiTierItemTick"};
    public ItemID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 129;
    }
}

export class Command_GetOpenOrderStates extends Command_CustomSession {
    public static GetBinaryID(): number {return  130};
    public static GetMessageType(): string {return  "Command_GetOpenOrderStates"};
    constructor() {
        super();
        this.BinaryID = 130;
    }
}

export class Answer_GetOpenOrderStates extends Answer_CustomSession {
    public static GetBinaryID(): number {return  131};
    public static GetMessageType(): string {return  "Answer_GetOpenOrderStates"};
    public OpenOrderStates: Message_OrderState[] = [];

    constructor() {
        super();
        this.BinaryID = 131;
    }
}

export class Command_RefreshToken extends Command_CustomSession {
    public static GetBinaryID(): number {return  132};
    public static GetMessageType(): string {return  "Command_RefreshToken"};
    public AccessToken: string  = null;
    public LoginName: string  = null;
    public TraderId: number  = 0;

    constructor() {
        super();
        this.BinaryID = 132;
    }
}

export class Message_SavedView extends Answer_CustomSession {
    public static GetBinaryID(): number {return  133};
    public static GetMessageType(): string {return  "Message_SavedView"};
    public TraderID: number  = 0;
    public ViewName: string  = null;
    public ViewData: string  = null;

    constructor() {
        super();
        this.BinaryID = 133;
    }
}

export class Command_GetAllSavedViews extends Command_CustomSession {
    public static GetBinaryID(): number {return  134};
    public static GetMessageType(): string {return  "Command_GetAllSavedViews"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 134;
    }
}

export class Answer_GetAllSavedViews extends Answer_CustomSession {
    public static GetBinaryID(): number {return  135};
    public static GetMessageType(): string {return  "Answer_GetAllSavedViews"};
    public ViewList: Message_SavedView[] = [];

    constructor() {
        super();
        this.BinaryID = 135;
    }
}

export class Command_SetSavedView extends Command_CustomSession {
    public static GetBinaryID(): number {return  136};
    public static GetMessageType(): string {return  "Command_SetSavedView"};
    public TraderID: number  = 0;
    public ViewName: string  = null;
    public ViewData: string  = null;

    constructor() {
        super();
        this.BinaryID = 136;
    }
}

export class Answer_SetSavedView extends Answer_CustomSession {
    public static GetBinaryID(): number {return  137};
    public static GetMessageType(): string {return  "Answer_SetSavedView"};
    public ID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 137;
    }
}

export class Command_GetSavedViewByName extends Command_CustomSession {
    public static GetBinaryID(): number {return  138};
    public static GetMessageType(): string {return  "Command_GetSavedViewByName"};
    public TraderID: number  = 0;
    public ViewName: string  = null;

    constructor() {
        super();
        this.BinaryID = 138;
    }
}

export class Answer_GetSavedViewByName extends Answer_CustomSession {
    public static GetBinaryID(): number {return  139};
    public static GetMessageType(): string {return  "Answer_GetSavedViewByName"};
    public ViewName: string  = null;
    public ViewData: string  = null;

    constructor() {
        super();
        this.BinaryID = 139;
    }
}

export class Notification_FixPosition extends Answer_CustomSession {
    public static GetBinaryID(): number {return  140};
    public static GetMessageType(): string {return  "Notification_FixPosition"};
    public DealID: number  = 0;
    public ClientOrderID: string  = null;
    public TraderID: number  = 0;
    public SymbolID: number  = 0;
    public OrderType: number  = 0;
    public DealState: number  = 0;
    public ExecutionPrice: number  = 0;
    public RequestAmount: number  = 0;
    public ExecutionAmount: number  = 0;
    public Text: string  = null;
    public Status: string  = null;

    constructor() {
        super();
        this.BinaryID = 140;
    }
}

export class Notification_PriceHub_FullMarketDepth extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  141};
    public static GetMessageType(): string {return  "Notification_PriceHub_FullMarketDepth"};
    public SymbolName: string  = null;
    public QuoteSourceName: string  = null;
    public PriceToPointMul: number  = 0;

    public LevelList: Notification_FullMarketDepth_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 141;
    }
}

export class Command_Authorization extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  142};
    public static GetMessageType(): string {return  "Command_Authorization"};
    public Account: string  = null;
    public AuthorizationString: string  = null;
    public Platform: string  = null;
    public GUID: string  = null;

    constructor() {
        super();
        this.BinaryID = 142;
    }
}

export class Notification_PriceHub_State_Changed extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  143};
    public static GetMessageType(): string {return  "Notification_PriceHub_State_Changed"};
    public Account: string  = null;
    public State: string  = null;

    constructor() {
        super();
        this.BinaryID = 143;
    }
}

export class Command_PriceHub_Retrieve_History extends Message_CustomCommand {
    public static GetBinaryID(): number {return  144};
    public static GetMessageType(): string {return  "Command_PriceHub_Retrieve_History"};
    public ItemID: number  = 0;
    public TimeFrom: Date  = new Date();
    public TimeTo: Date  = new Date();
    public ZoomType: string  = null;
    public RetrieveMode: number  = 0;

    constructor() {
        super();
        this.BinaryID = 144;
    }
}

export class Answer_PriceHub_Retrieve_History extends Answer_CustomSession {
    public static GetBinaryID(): number {return  145};
    public static GetMessageType(): string {return  "Answer_PriceHub_Retrieve_History"};
    public JsonItems: string  = null;
    public ZoomType: string  = null;
    public ServerTime: Date  = new Date();
    public StartOfHistory: Date  = new Date();
    public ItemId: number  = 0;

    constructor() {
        super();
        this.BinaryID = 145;
    }
}

export class Command_PriceHub_Init_History extends Message_CustomCommand {
    public static GetBinaryID(): number {return  146};
    public static GetMessageType(): string {return  "Command_PriceHub_Init_History"};
    public ItemID: number  = 0;
    public Count: number  = 0;
    public ZoomType: string  = null;
    public RetrieveMode: number  = 0;

    constructor() {
        super();
        this.BinaryID = 146;
    }
}

export class Command_PriceHub_Retrieve_Last_Ticks extends Message_CustomCommand {
    public static GetBinaryID(): number {return  147};
    public static GetMessageType(): string {return  "Command_PriceHub_Retrieve_Last_Ticks"};
    public ItemsList: Integer_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 147;
    }
}

export class Tick_Item extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  148};
    public static GetMessageType(): string {return  "Tick_Item"};
    public Timestamp: Date  = new Date();
    public ItemId: number  = 0;
    public Ask: number  = 0;
    public Bid: number  = 0;
    public ConversionToUSD: number  = 0;

    constructor() {
        super();
        this.BinaryID = 148;
    }
}

export class Answer_PriceHub_Retrieve_Last_Ticks extends Answer_CustomSession {
    public static GetBinaryID(): number {return  149};
    public static GetMessageType(): string {return  "Answer_PriceHub_Retrieve_Last_Ticks"};
    public TickItems: Tick_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 149;
    }
}

export class Sourced_ItemHistory_Item extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  150};
    public static GetMessageType(): string {return  "Sourced_ItemHistory_Item"};
    public ItemID: number  = 0;
    public Zoom: string  = null;
    public OpenDate: Date  = new Date();
    public OpenPrice: number  = 0;
    public ClosePrice: number  = 0;
    public HighPrice: number  = 0;
    public LowPrice: number  = 0;
    public ItemVolume: number  = 0;
    public CloseSpread: number  = 0;
    public CloseConversionToUSD: number  = 0;

    constructor() {
        super();
        this.BinaryID = 150;
    }
}

export class Command_Retrieve_Dirty_Candles extends Message_CustomCommand {
    public static GetBinaryID(): number {return  151};
    public static GetMessageType(): string {return  "Command_Retrieve_Dirty_Candles"};
    constructor() {
        super();
        this.BinaryID = 151;
    }
}

export class Sourced_ItemHistory extends Answer_CustomSession {
    public static GetBinaryID(): number {return  152};
    public static GetMessageType(): string {return  "Sourced_ItemHistory"};
    public ItemHistoryItems: Sourced_ItemHistory_Item[] = [];

    constructor() {
        super();
        this.BinaryID = 152;
    }
}

export class Command_PriceHub_RetrieveMarketData extends Message_CustomCommand {
    public static GetBinaryID(): number {return  153};
    public static GetMessageType(): string {return  "Command_PriceHub_RetrieveMarketData"};
    public ItemID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 153;
    }
}

export class Answer_PriceHub_RetrieveMarketData extends Answer_CustomSession {
    public static GetBinaryID(): number {return  154};
    public static GetMessageType(): string {return  "Answer_PriceHub_RetrieveMarketData"};
    public JsonItems: string  = null;
    public LastChecked: Date  = new Date();
    public StartDate: Date  = new Date();
    public EndDate: Date  = new Date();
    public CIK: string  = null;
    public CompanyName: string  = null;
    public FiscalPeriod: string  = null;
    public FiscalYear: string  = null;

    constructor() {
        super();
        this.BinaryID = 154;
    }
}

export class Command_RefreshToken2 extends Command_CustomSession {
    public static GetBinaryID(): number {return  155};
    public static GetMessageType(): string {return  "Command_RefreshToken2"};
    public AccessToken: string  = null;
    public LoginName: string  = null;

    constructor() {
        super();
        this.BinaryID = 155;
    }
}

export class Answer_RefreshToken2 extends Answer_CustomSession {
    public static GetBinaryID(): number {return  156};
    public static GetMessageType(): string {return  "Answer_RefreshToken2"};
    public NewAccessToken: string  = null;
    public LoginName: string  = null;

    constructor() {
        super();
        this.BinaryID = 156;
    }
}

export class Answer_RefreshToken extends Command_CustomSession {
    public static GetBinaryID(): number {return  157};
    public static GetMessageType(): string {return  "Answer_RefreshToken"};
    public NewAccessToken: string  = null;
    public LoginName: string  = null;
    public TraderId: number  = 0;

    constructor() {
        super();
        this.BinaryID = 157;
    }
}

export class Notification_TradeGroupCreated extends Notification_CustomSession {
    public static GetBinaryID(): number {return  158};
    public static GetMessageType(): string {return  "Notification_TradeGroupCreated"};
    public TradeGroupID: number  = 0;

    public Symbols: Message_SymbolSettings[] = [];

    constructor() {
        super();
        this.BinaryID = 158;
    }
}

export class Command_WatchListCreate extends Command_CustomSession {
    public static GetBinaryID(): number {return  159};
    public static GetMessageType(): string {return  "Command_WatchListCreate"};
    public WatchListName: string  = null;
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 159;
    }
}

export class Command_WatchListDelete extends Command_CustomSession {
    public static GetBinaryID(): number {return  160};
    public static GetMessageType(): string {return  "Command_WatchListDelete"};
    public WatchListID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 160;
    }
}

export class Command_WatchListAddSymbol extends Command_CustomSession {
    public static GetBinaryID(): number {return  161};
    public static GetMessageType(): string {return  "Command_WatchListAddSymbol"};
    public WatchListID: number  = 0;
    public SymbolID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 161;
    }
}

export class Command_WatchListRemoveSymbol extends Command_CustomSession {
    public static GetBinaryID(): number {return  162};
    public static GetMessageType(): string {return  "Command_WatchListRemoveSymbol"};
    public WatchListContentID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 162;
    }
}

export class Command_WatchListUpdate extends Command_CustomSession {
    public static GetBinaryID(): number {return  163};
    public static GetMessageType(): string {return  "Command_WatchListUpdate"};
    public WatchListID: number  = 0;
    public NewWatchListName: string  = null;

    constructor() {
        super();
        this.BinaryID = 163;
    }
}

export class Answer_WatchListCreate extends Answer_CustomSession {
    public static GetBinaryID(): number {return  164};
    public static GetMessageType(): string {return  "Answer_WatchListCreate"};
    public WatchListID: number  = 0;
    public WatchListName: string  = null;
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 164;
    }
}

export class Answer_WatchListAddSymbol extends Answer_CustomSession {
    public static GetBinaryID(): number {return  165};
    public static GetMessageType(): string {return  "Answer_WatchListAddSymbol"};
    public WatchListID: number  = 0;
    public WatchListContentID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 165;
    }
}

export class Answer_WatchListUpdate extends Answer_CustomSession {
    public static GetBinaryID(): number {return  166};
    public static GetMessageType(): string {return  "Answer_WatchListUpdate"};
    public WatchListID: number  = 0;
    public WatchListName: string  = null;

    constructor() {
        super();
        this.BinaryID = 166;
    }
}

export class Message_WatchList extends Answer_CustomSession {
    public static GetBinaryID(): number {return  167};
    public static GetMessageType(): string {return  "Message_WatchList"};
    public WatchListID: number  = 0;
    public WatchListName: string  = null;
    public TraderID: number  = 0;
    public WatchListContentID: number  = 0;
    public SymbolID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 167;
    }
}

export class Command_WatchListRetrieveAllByTraderId extends Command_CustomSession {
    public static GetBinaryID(): number {return  168};
    public static GetMessageType(): string {return  "Command_WatchListRetrieveAllByTraderId"};
    public TraderId: number  = 0;

    constructor() {
        super();
        this.BinaryID = 168;
    }
}

export class Answer_WatchListRetrieveAllByTraderId extends Answer_CustomSession {
    public static GetBinaryID(): number {return  169};
    public static GetMessageType(): string {return  "Answer_WatchListRetrieveAllByTraderId"};
    public TraderID: number  = 0;

    public WatchLists: Message_WatchList[] = [];

    constructor() {
        super();
        this.BinaryID = 169;
    }
}

export class Message_WatchListSuggestion extends Answer_CustomSession {
    public static GetBinaryID(): number {return  170};
    public static GetMessageType(): string {return  "Message_WatchListSuggestion"};
    public WatchListSuggestionID: number  = 0;
    public WatchListSuggestionName: string  = null;
    public WatchListSuggestionUserID: number  = 0;
    public TraderID: number  = 0;
    public WatchListSuggestionContentID: number  = 0;
    public SymbolID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 170;
    }
}

export class Command_WatchListSuggestionsRetrieveAllByTraderId extends Command_CustomSession {
    public static GetBinaryID(): number {return  171};
    public static GetMessageType(): string {return  "Command_WatchListSuggestionsRetrieveAllByTraderId"};
    public TraderId: number  = 0;

    constructor() {
        super();
        this.BinaryID = 171;
    }
}

export class Answer_WatchListSuggestionsRetrieveAllByTraderId extends Answer_CustomSession {
    public static GetBinaryID(): number {return  172};
    public static GetMessageType(): string {return  "Answer_WatchListSuggestionsRetrieveAllByTraderId"};
    public TraderID: number  = 0;

    public WatchListSuggestions: Message_WatchListSuggestion[] = [];

    constructor() {
        super();
        this.BinaryID = 172;
    }
}

export class Command_GetSumSubToken extends Command_CustomSession {
    public static GetBinaryID(): number {return  173};
    public static GetMessageType(): string {return  "Command_GetSumSubToken"};
    public TraderId: number  = 0;
    public ExternalID: string  = null;

    constructor() {
        super();
        this.BinaryID = 173;
    }
}

export class Answer_GetSumSubToken extends Answer_CustomSession {
    public static GetBinaryID(): number {return  174};
    public static GetMessageType(): string {return  "Answer_GetSumSubToken"};
    public Token: string  = null;
    public ExternalID: string  = null;

    constructor() {
        super();
        this.BinaryID = 174;
    }
}

export class Command_CreateExternalAccount extends Command_CustomSession {
    public static GetBinaryID(): number {return  175};
    public static GetMessageType(): string {return  "Command_CreateExternalAccount"};
    public externalAccountID: string  = null;
    public customerFullNameLine1: string  = null;
    public customerFullNameLine2: string  = null;
    public customerShortName: string  = null;
    public customerState: string  = null;
    public customerCountry: number  = 0;
    public customerAddressLine1: string  = null;
    public customerAddressLine2: string  = null;
    public customerStreetName: string  = null;
    public customerCity: string  = null;
    public customerPostCode: string  = null;
    public customerPhoneNumber: string  = null;
    public customerPhoneNumber2: string  = null;
    public customerMobilePhoneNumber: string  = null;
    public customerMobilePhoneNumber2: string  = null;
    public customerFaxNumber: string  = null;
    public timestamp: string  = null;
    public accountCurrencyID: number  = 0;
    public accountOpenActualBalance: number  = 0;
    public accountPersonalAccountEmail: string  = null;
    public accountPersonalAccountEmail2: string  = null;
    public accountLogin: string  = null;
    public accountPasswordHash: string  = null;
    public accountPasswordHashFunction: string  = null;
    public accountGroupName: string  = null;
    public comments: string  = null;
    public isInvestorAccount: boolean  = false;
    public firstName: string  = null;
    public middleName: string  = null;
    public surname: string  = null;
    public Gender: string  = null;
    public DateOfBirth: Date  = new Date();
    public MaritalStatus: string  = null;
    public FullResidenceAddress: string  = null;
    public TelephoneNumber: string  = null;
    public EmailAddress: string  = null;
    public TaxIdNumber: string  = null;
    public EducationLevel: string  = null;
    public EmploymentStatus: string  = null;
    public EmployerName: string  = null;
    public NatureOfTheBusiness: string  = null;
    public InvestmentExperience: string  = null;
    public TypeOfInvestmentExprience: string  = null;
    public AccountType: string  = null;
    public TypeOfTrading: string  = null;
    public EstimateNetWorth: string  = null;
    public EstimateAnnualIncome: string  = null;
    public EstimateLiquidNetWorth: string  = null;
    public SourceOfFunds: string  = null;
    public EstimateInitialDeposit: string  = null;
    public EstimateWithdrawalFrequency: string  = null;
    public RiskTrade: string  = null;
    public HowHearAboutAlchemy: string  = null;

    constructor() {
        super();
        this.BinaryID = 175;
    }
}

export class Answer_CreateExternalAccount extends Answer_CustomSession {
    public static GetBinaryID(): number {return  176};
    public static GetMessageType(): string {return  "Answer_CreateExternalAccount"};
    public LoginName: string  = null;
    public EmaiAddress: string  = null;

    constructor() {
        super();
        this.BinaryID = 176;
    }
}

export class Answer_CreateExternalAccountError extends Answer_CustomSession {
    public static GetBinaryID(): number {return  177};
    public static GetMessageType(): string {return  "Answer_CreateExternalAccountError"};
    public ErrorCode: number  = 0;
    public ErrorMessage: string  = null;

    constructor() {
        super();
        this.BinaryID = 177;
    }
}

export class Command_UpdateExternalAccount extends Command_CustomSession {
    public static GetBinaryID(): number {return  178};
    public static GetMessageType(): string {return  "Command_UpdateExternalAccount"};
    public externalAccountID: string  = null;
    public customerFullNameLine1: string  = null;
    public customerFullNameLine2: string  = null;
    public customerShortName: string  = null;
    public customerState: string  = null;
    public customerCountry: number  = 0;
    public customerAddressLine1: string  = null;
    public customerAddressLine2: string  = null;
    public customerStreetName: string  = null;
    public customerCity: string  = null;
    public customerPostCode: string  = null;
    public customerPhoneNumber: string  = null;
    public customerPhoneNumber2: string  = null;
    public customerMobilePhoneNumber: string  = null;
    public customerMobilePhoneNumber2: string  = null;
    public customerFaxNumber: string  = null;
    public timestamp: string  = null;
    public accountCurrencyID: number  = 0;
    public accountOpenActualBalance: number  = 0;
    public accountPersonalAccountEmail: string  = null;
    public accountPersonalAccountEmail2: string  = null;
    public accountLogin: string  = null;
    public accountPasswordHash: string  = null;
    public accountGroupName: string  = null;
    public comments: string  = null;
    public introducingBrokerPersonalAccountID: number  = 0;
    public passwordMustBeChanged: boolean  = false;
    public allowMultyLogin: boolean  = false;
    public isInvestorAccount: boolean  = false;
    public FirstName: string  = null;
    public MiddleName: string  = null;
    public Surname: string  = null;
    public Gender: string  = null;
    public DateOfBirth: Date  = new Date();
    public MaritalStatus: string  = null;
    public FullResidenceAddress: string  = null;
    public TelephoneNumber: string  = null;
    public EmailAddress: string  = null;
    public TaxIdNumber: string  = null;
    public EducationLevel: string  = null;
    public EmploymentStatus: string  = null;
    public EmployerName: string  = null;
    public NatureOfTheBusiness: string  = null;
    public InvestmentExperience: string  = null;
    public TypeOfInvestmentExperience: string  = null;
    public AccountType: string  = null;
    public TypeOfTrading: string  = null;
    public EstimateNetWorth: string  = null;
    public EstimateAnnualIncome: string  = null;
    public EstimateLiquidNetWorth: string  = null;
    public SourceOfFunds: string  = null;
    public EstimateInitialDeposit: string  = null;
    public EstimateWithdrawalFrequency: string  = null;
    public RiskTrade: string  = null;
    public HowHearAboutAlchemy: string  = null;

    constructor() {
        super();
        this.BinaryID = 178;
    }
}

export class Answer_UpdateExternalAccount extends Answer_CustomSession {
    public static GetBinaryID(): number {return  179};
    public static GetMessageType(): string {return  "Answer_UpdateExternalAccount"};
    constructor() {
        super();
        this.BinaryID = 179;
    }
}

export class Answer_UpdateExternalAccountError extends Answer_CustomSession {
    public static GetBinaryID(): number {return  180};
    public static GetMessageType(): string {return  "Answer_UpdateExternalAccountError"};
    public ErrorCode: number  = 0;
    public ErrorMessage: string  = null;

    constructor() {
        super();
        this.BinaryID = 180;
    }
}

export class Message_TraderOrderHistory extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  190};
    public static GetMessageType(): string {return  "Message_TraderOrderHistory"};
    public OrderID: number  = 0;
    public StateID: number  = 0;
    public TraderID: number  = 0;
    public SymbolID: number  = 0;
    public OrderTypeID: number  = 0;
    public Volume: number  = 0;
    public OpenPrice: number  = 0;
    public StopLoss: number  = 0;
    public TakeProfit: number  = 0;
    public TrailingStop: number  = 0;
    public CommandVolume: number  = 0;
    public CommandPrice: number  = 0;
    public CommandTime: Date  = new Date();
    public CommandTypeID: number  = 0;
    public CommandOriginID: number  = 0;
    public CommandSenderID: number  = 0;
    public Margin: number  = 0;
    public CommandMargin: number  = 0;
    public Comment: string  = null;
    public ExpirationDate: Date  = new Date();
    public ClientOrderID: string  = null;
    public DealID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 190;
    }
}

export class Message_TraderBalanceHistory extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  191};
    public static GetMessageType(): string {return  "Message_TraderBalanceHistory"};
    public TraderID: number  = 0;
    public BalanceTypeID: number  = 0;
    public Profit: number  = 0;
    public Comment: string  = null;

    constructor() {
        super();
        this.BinaryID = 191;
    }
}

export class Command_CustomSession_Paging extends Command_CustomSession {
    public static GetBinaryID(): number {return  192};
    public static GetMessageType(): string {return  "Command_CustomSession_Paging"};
    public Page: number  = 0;
    public Rows: number  = 0;
    public RetrieveAll: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 192;
    }
}

export class Command_GetTraderOrderHistory extends Command_CustomSession_Paging {
    public static GetBinaryID(): number {return  193};
    public static GetMessageType(): string {return  "Command_GetTraderOrderHistory"};
    constructor() {
        super();
        this.BinaryID = 193;
    }
}

export class Answer_ReportCenter extends Answer_CustomSession {
    public static GetBinaryID(): number {return  194};
    public static GetMessageType(): string {return  "Answer_ReportCenter"};
    public Result: string  = null;

    constructor() {
        super();
        this.BinaryID = 194;
    }
}

export class Answer_ReportCenterError extends Answer_CustomSession {
    public static GetBinaryID(): number {return  195};
    public static GetMessageType(): string {return  "Answer_ReportCenterError"};
    public ErrorMessage: string  = null;

    constructor() {
        super();
        this.BinaryID = 195;
    }
}

export class Answer_GetTraderOrderHistory extends Answer_ReportCenter {
    public static GetBinaryID(): number {return  196};
    public static GetMessageType(): string {return  "Answer_GetTraderOrderHistory"};
    constructor() {
        super();
        this.BinaryID = 196;
    }
}

export class Command_TraderSignAgreement extends Command_CustomSession {
    public static GetBinaryID(): number {return  197};
    public static GetMessageType(): string {return  "Command_TraderSignAgreement"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 197;
    }
}

export class Answer_TraderSignAgreement extends Answer_CustomSession {
    public static GetBinaryID(): number {return  198};
    public static GetMessageType(): string {return  "Answer_TraderSignAgreement"};
    public isSaved: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 198;
    }
}

export class Integer_Item extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  199};
    public static GetMessageType(): string {return  "Integer_Item"};
    public Value: number  = 0;

    constructor() {
        super();
        this.BinaryID = 199;
    }
}

export class Message_OrderStorage_GroupItem extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  200};
    public static GetMessageType(): string {return  "Message_OrderStorage_GroupItem"};
    public SymbolID: number  = 0;
    public PointPrice: number  = 0;
    public InitialMarginRate: number  = 0;
    public MaintenanceMarginRate: number  = 0;
    public BidCorrection: number  = 0;
    public AskCorrection: number  = 0;
    public SpreadCorrectionType: number  = 0;
    public SpreadCorrectionValue: number  = 0;

    constructor() {
        super();
        this.BinaryID = 200;
    }
}

export class Message_OrderStorage_Group extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  201};
    public static GetMessageType(): string {return  "Message_OrderStorage_Group"};
    public GroupID: number  = 0;
    public GroupName: string  = null;
    public MarginCallLevel: number  = 0;
    public LiquidityProviderPersonalAccountId: number  = 0;
    public MinimumSpread: number  = 0;
    public IsTradingDisabled: boolean  = false;
    public ShowAlgoSubscriptions: boolean  = false;
    public ShowMostPopularStrategies: boolean  = false;
    public UseVolumeTrading: boolean  = false;
    public IsExternal: boolean  = false;

    public GroupItems: Message_OrderStorage_GroupItem[] = [];

    constructor() {
        super();
        this.BinaryID = 201;
    }
}

export class Command_Retrieve_OrderStorage_Groups extends Command_CustomSession {
    public static GetBinaryID(): number {return  202};
    public static GetMessageType(): string {return  "Command_Retrieve_OrderStorage_Groups"};
    constructor() {
        super();
        this.BinaryID = 202;
    }
}

export class Answer_Retrieve_OrderStorage_Groups extends Answer_CustomSession {
    public static GetBinaryID(): number {return  203};
    public static GetMessageType(): string {return  "Answer_Retrieve_OrderStorage_Groups"};
    public Groups: Message_OrderStorage_Group[] = [];

    constructor() {
        super();
        this.BinaryID = 203;
    }
}

export class Message_OrderStorage_Order extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  204};
    public static GetMessageType(): string {return  "Message_OrderStorage_Order"};
    public OrderID: number  = 0;
    public AccountID: number  = 0;
    public GroupID: number  = 0;
    public ItemID: number  = 0;
    public OpenPrice: number  = 0;
    public TakeProfit: number  = 0;
    public StopLoss: number  = 0;
    public Volume: number  = 0;
    public OrderType: number  = 0;

    constructor() {
        super();
        this.BinaryID = 204;
    }
}

export class Message_OrderStorage_MarketOrder extends Message_OrderStorage_Order {
    public static GetBinaryID(): number {return  205};
    public static GetMessageType(): string {return  "Message_OrderStorage_MarketOrder"};
    public Margin: number  = 0;
    public Profit: number  = 0;
    public TrailingStop: number  = 0;
    public TrailingStopValue: number  = 0;
    public ClientOrderID: string  = null;

    constructor() {
        super();
        this.BinaryID = 205;
    }
}

export class Message_OrderStorage_PendingOrder extends Message_OrderStorage_Order {
    public static GetBinaryID(): number {return  206};
    public static GetMessageType(): string {return  "Message_OrderStorage_PendingOrder"};
    constructor() {
        super();
        this.BinaryID = 206;
    }
}

export class Message_OrderStorage_Position extends Message_OrderStorage_Order {
    public static GetBinaryID(): number {return  207};
    public static GetMessageType(): string {return  "Message_OrderStorage_Position"};
    constructor() {
        super();
        this.BinaryID = 207;
    }
}

export class Command_Retrieve_OrderStorage_Orders extends Command_CustomSession {
    public static GetBinaryID(): number {return  208};
    public static GetMessageType(): string {return  "Command_Retrieve_OrderStorage_Orders"};
    public CoprocessorID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 208;
    }
}

export class Message_OrderStorage_Account extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  209};
    public static GetMessageType(): string {return  "Message_OrderStorage_Account"};
    public AccountID: number  = 0;
    public GroupID: number  = 0;
    public Balance: number  = 0;
    public LossLimit: number  = 0;
    public LossLimitRate: number  = 0;
    public MarginCallSentTime: Date  = new Date();
    public MarginCallMailEnabled: boolean  = false;
    public LiquidationMailEnabled: boolean  = false;
    public LiquidationTime: Date  = new Date();
    public ExpirationDate: Date  = new Date();
    public IsExpired: boolean  = false;
    public LiquidationMargin: number  = 0;
    public MarginLevel: number  = 0;
    public MarginStatus: number  = 0;
    public AccountType: number  = 0;
    public Commision: number  = 0;
    public TransactionFeeOpen: number  = 0;
    public TransactionFeeClose: number  = 0;

    constructor() {
        super();
        this.BinaryID = 209;
    }
}

export class Answer_Retrieve_OrderStorage_Orders extends Answer_CustomSession {
    public static GetBinaryID(): number {return  210};
    public static GetMessageType(): string {return  "Answer_Retrieve_OrderStorage_Orders"};
    public MarketOrders: Message_OrderStorage_MarketOrder[] = [];
    public PendingOrders: Message_OrderStorage_PendingOrder[] = [];
    public Positions: Message_OrderStorage_Position[] = [];
    public Accounts: Message_OrderStorage_Account[] = [];

    constructor() {
        super();
        this.BinaryID = 210;
    }
}

export class Command_Coprocessor_Init extends Command_CustomSession {
    public static GetBinaryID(): number {return  211};
    public static GetMessageType(): string {return  "Command_Coprocessor_Init"};
    public StorageNum: number  = 0;

    constructor() {
        super();
        this.BinaryID = 211;
    }
}

export class Command_Coprocessor_Authorization extends Command_CustomSession {
    public static GetBinaryID(): number {return  212};
    public static GetMessageType(): string {return  "Command_Coprocessor_Authorization"};
    public StorageNum: number  = 0;
    public AuthorizationString: string  = null;

    constructor() {
        super();
        this.BinaryID = 212;
    }
}

export class Command_Deal extends Command_CustomSession {
    public static GetBinaryID(): number {return  213};
    public static GetMessageType(): string {return  "Command_Deal"};
    public DealId: number  = 0;
    public StateId: number  = 0;
    public ServerClientSessionId: string  = null;
    public ServerClientInitialCommandID: number  = 0;
    public DealInitiator: number  = 0;

    constructor() {
        super();
        this.BinaryID = 213;
    }
}

export class Command_Deal_Common extends Command_Deal {
    public static GetBinaryID(): number {return  214};
    public static GetMessageType(): string {return  "Command_Deal_Common"};
    public ItemId: number  = 0;
    public AccountId: number  = 0;
    public BrokerId: number  = 0;
    public OrderCommandReason: number  = 0;
    public OrderCommandId: number  = 0;
    public IsDeclined: boolean  = false;
    public ProcessInHouse: boolean  = false;
    public Comment: string  = null;
    public ClientOrderId: string  = null;
    public ExternalAccount: string  = null;

    constructor() {
        super();
        this.BinaryID = 214;
    }
}

export class Command_Deal_OpenMarketOrder extends Command_Deal_Common {
    public static GetBinaryID(): number {return  215};
    public static GetMessageType(): string {return  "Command_Deal_OpenMarketOrder"};
    public TakeProfit: number  = 0;
    public OpenPrice: number  = 0;
    public StopLoss: number  = 0;
    public OrderType: number  = 0;
    public TrailingStop: number  = 0;
    public RequestPrice: number  = 0;
    public FillOrKill: boolean  = false;
    public Deviation: number  = 0;
    public Volume: number  = 0;

    constructor() {
        super();
        this.BinaryID = 215;
    }
}

export class Command_TicketNumber extends Command_CustomSession {
    public static GetBinaryID(): number {return  216};
    public static GetMessageType(): string {return  "Command_TicketNumber"};
    public DealID: number  = 0;
    public IsDealCreated: boolean  = false;
    public ServerClientSessionID: string  = null;
    public ServerClientInitialCommandID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 216;
    }
}

export class Command_TicketDecline extends Command_CustomSession {
    public static GetBinaryID(): number {return  217};
    public static GetMessageType(): string {return  "Command_TicketDecline"};
    public DealID: number  = 0;
    public Result: number  = 0;
    public ServerClientSessionID: string  = null;
    public Message: string  = null;
    public IsDealCreated: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 217;
    }
}

export class Command_TicketAccept extends Command_CustomSession {
    public static GetBinaryID(): number {return  218};
    public static GetMessageType(): string {return  "Command_TicketAccept"};
    public DealID: number  = 0;
    public Result: number  = 0;
    public ServerClientSessionID: string  = null;

    constructor() {
        super();
        this.BinaryID = 218;
    }
}

export class Command_GetTraderTransferHistory extends Command_CustomSession_Paging {
    public static GetBinaryID(): number {return  219};
    public static GetMessageType(): string {return  "Command_GetTraderTransferHistory"};
    constructor() {
        super();
        this.BinaryID = 219;
    }
}

export class Command_GetEquityChartData extends Command_CustomSession {
    public static GetBinaryID(): number {return  220};
    public static GetMessageType(): string {return  "Command_GetEquityChartData"};
    public TraderID: number  = 0;
    public Resolution: number  = 0;
    public PeriodBegin: string  = null;
    public PeriodEnd: string  = null;
    public TakeLastCount: number  = 0;

    constructor() {
        super();
        this.BinaryID = 220;
    }
}

export class Command_GetTraderGainSummary extends Command_CustomSession {
    public static GetBinaryID(): number {return  221};
    public static GetMessageType(): string {return  "Command_GetTraderGainSummary"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 221;
    }
}

export class Command_GetRssNews extends Command_CustomSession {
    public static GetBinaryID(): number {return  222};
    public static GetMessageType(): string {return  "Command_GetRssNews"};
    constructor() {
        super();
        this.BinaryID = 222;
    }
}

export class Command_GetTradingCentralToken extends Command_CustomSession {
    public static GetBinaryID(): number {return  223};
    public static GetMessageType(): string {return  "Command_GetTradingCentralToken"};
    constructor() {
        super();
        this.BinaryID = 223;
    }
}

export class Command_Coprocessor_BalanceChanged extends Command_CustomSession {
    public static GetBinaryID(): number {return  224};
    public static GetMessageType(): string {return  "Command_Coprocessor_BalanceChanged"};
    public Balance: number  = 0;
    public TraderID: number  = 0;
    public NotificationID: number  = 0;
    public ServerTime: Date  = new Date();

    constructor() {
        super();
        this.BinaryID = 224;
    }
}

export class Command_Coprocessor_SendOrderStates extends Command_CustomSession {
    public static GetBinaryID(): number {return  225};
    public static GetMessageType(): string {return  "Command_Coprocessor_SendOrderStates"};
    public AccountID: number  = 0;
    public DealID: number  = 0;

    public OrderStates: Message_OrderState[] = [];

    constructor() {
        super();
        this.BinaryID = 225;
    }
}

export class Command_Deal_CloseMarketOrder extends Command_Deal_Common {
    public static GetBinaryID(): number {return  226};
    public static GetMessageType(): string {return  "Command_Deal_CloseMarketOrder"};
    public CloseTime: Date  = new Date();
    public ClosePrice: number  = 0;
    public RequestPrice: number  = 0;
    public Deviation: number  = 0;
    public Volume: number  = 0;
    public OrderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 226;
    }
}

export class ServerConfigs extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  227};
    public static GetMessageType(): string {return  "ServerConfigs"};
    public InstanceName: string  = null;
    public BackOfficeBaseURL: string  = null;
    public BackOfficeAdminLoginName: string  = null;
    public BackOfficeAdminPassword: string  = null;
    public MailMessagesPerCommand: number  = 0;
    public EnableMarketDepth: boolean  = false;
    public TimeZone: string  = null;
    public EnableSlippage: boolean  = false;
    public AllowCloseOrderWithoutTSCheck: boolean  = false;
    public PriceOutdate: number  = 0;
    public SymbolTradingStatusCheck: boolean  = false;
    public WebServicePriceDeltaCheck: boolean  = false;
    public MaxPendingStopDeviation: number  = 0;
    public MaxPendingLimitDeviation: number  = 0;
    public EnableDailyFX: boolean  = false;
    public EnableAlgo: boolean  = false;
    public EnableAdvertisementOnClose: boolean  = false;
    public IsSlave: boolean  = false;
    public ExceptionMessage: string  = null;
    public ParallelSymbolLoadEnabled: boolean  = false;
    public ParallelSymbolLoadThreadNumber: number  = 0;
    public DefaultSessionLimitNumber: number  = 0;
    public EnableDebugMessages: boolean  = false;
    public UsePriceHub: boolean  = false;
    public PriceHistoryProcessing: boolean  = false;
    public PriceHubAuthorizationString: string  = null;
    public PriceHubConectionTimeout: number  = 0;
    public PriceHubAnswerTimeout: number  = 0;
    public FullMDProcessing: boolean  = false;
    public InitialTicksProcessing: boolean  = false;
    public StopWatchEnable: boolean  = false;
    public UseCoprocessor: boolean  = false;
    public CoprocessorConectionTimeout: number  = 0;
    public CoprocessorAnswerTimeout: number  = 0;
    public CoprocessorAuthorizationString: string  = null;
    public ProfitCalculationMode: string  = null;
    public RecentlyClosedTimeoutInSeconds: number  = 0;

    constructor() {
        super();
        this.BinaryID = 227;
    }
}

export class Command_Deal_UpdateStops extends Command_Deal_Common {
    public static GetBinaryID(): number {return  228};
    public static GetMessageType(): string {return  "Command_Deal_UpdateStops"};
    public OrderID: number  = 0;
    public StopLoss: number  = 0;
    public TakeProfit: number  = 0;
    public TrailingStop: number  = 0;
    public ActualDealType: number  = 0;

    constructor() {
        super();
        this.BinaryID = 228;
    }
}

export class Command_PlaceMarketOrder extends Command_CustomSession {
    public static GetBinaryID(): number {return  229};
    public static GetMessageType(): string {return  "Command_PlaceMarketOrder"};
    public ItemID: number  = 0;
    public DealID: number  = 0;
    public Volume: number  = 0;
    public OrderID: number  = 0;
    public TraderID: number  = 0;
    public DealOrigin: number  = 0;
    public Deviation: number  = 0;
    public RequestPrice: number  = 0;
    public FillOrKill: boolean  = false;
    public ContractSize: number  = 0;
    public OrderType: number  = 0;
    public ExternalAccount: string  = null;

    constructor() {
        super();
        this.BinaryID = 229;
    }
}

export class Answer_PlaceMarketOrder extends Answer_CustomSession {
    public static GetBinaryID(): number {return  230};
    public static GetMessageType(): string {return  "Answer_PlaceMarketOrder"};
    public DealID: number  = 0;
    public Volume: number  = 0;
    public MarketProviderPrice: number  = 0;
    public IsDeclined: boolean  = false;
    public DeclineReason: string  = null;
    public StateID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 230;
    }
}

export class Message_ExternalGroup extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  231};
    public static GetMessageType(): string {return  "Message_ExternalGroup"};
    public Balance: number  = 0;
    public TradeGroupID: number  = 0;

    public Items: Message_ExternalGroupItem[] = [];

    constructor() {
        super();
        this.BinaryID = 231;
    }
}

export class Message_ExternalGroupItem extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  232};
    public static GetMessageType(): string {return  "Message_ExternalGroupItem"};
    public Amount: number  = 0;
    public ItemName: string  = null;
    public ItemID: number  = 0;
    public GroupID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 232;
    }
}

export class Message_SystemCurrency extends Message_CustomSession {
    public static GetBinaryID(): number {return  233};
    public static GetMessageType(): string {return  "Message_SystemCurrency"};
    public CurrencyName: string  = null;
    public RateBid: number  = 0;
    public RateType: number  = 0;
    public RateAsk: number  = 0;
    public CurrencyID: number  = 0;
    public RateSymbolID: number  = 0;
    public DocumentPrecision: number  = 0;

    constructor() {
        super();
        this.BinaryID = 233;
    }
}

export class Command_Coprocessor_Inited extends Command_CustomSession {
    public static GetBinaryID(): number {return  234};
    public static GetMessageType(): string {return  "Command_Coprocessor_Inited"};
    public CoprocessorID: number  = 0;

    public MarketOrders: Message_OrderState[] = [];
    public PendingOrders: Message_OrderState[] = [];

    constructor() {
        super();
        this.BinaryID = 234;
    }
}

export class Command_Coprocessor_GetOpenOrderStates extends Command_CustomSession {
    public static GetBinaryID(): number {return  235};
    public static GetMessageType(): string {return  "Command_Coprocessor_GetOpenOrderStates"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 235;
    }
}

export class Command_BlackthornAuthenticate extends Command_CustomSession {
    public static GetBinaryID(): number {return  236};
    public static GetMessageType(): string {return  "Command_BlackthornAuthenticate"};
    public TraderID: number  = 0;
    public OrderNumber: string  = null;
    public OrderAmount: string  = null;
    public OrderCurrency: string  = null;
    public OrderDescription: string  = null;
    public SuccessURL: string  = null;
    public CancelURL: string  = null;
    public CustomerName: string  = null;

    constructor() {
        super();
        this.BinaryID = 236;
    }
}

export class Command_MarkMailRead extends Command_CustomSession {
    public static GetBinaryID(): number {return  237};
    public static GetMessageType(): string {return  "Command_MarkMailRead"};
    public MailID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 237;
    }
}

export class Command_DeleteMail extends Command_CustomSession {
    public static GetBinaryID(): number {return  238};
    public static GetMessageType(): string {return  "Command_DeleteMail"};
    public MailID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 238;
    }
}

export class Command_SaveError extends Command_CustomSession {
    public static GetBinaryID(): number {return  239};
    public static GetMessageType(): string {return  "Command_SaveError"};
    public PersonalAccountId: number  = 0;
    public LoginName: string  = null;
    public Ip: string  = null;
    public Timestamp: string  = null;
    public Application: string  = null;
    public AppVersion: string  = null;
    public Context: string  = null;
    public Message: string  = null;
    public StackTrace: string  = null;

    constructor() {
        super();
        this.BinaryID = 239;
    }
}

export class Command_GetTopGainersLoserReport extends Command_CustomSession {
    public static GetBinaryID(): number {return  240};
    public static GetMessageType(): string {return  "Command_GetTopGainersLoserReport"};
    public TraderID: number  = 0;
    public DirectOrder: boolean  = false;
    public TopRecordsNumber: number  = 0;

    constructor() {
        super();
        this.BinaryID = 240;
    }
}

export class Command_GetAssetMarketData extends Command_CustomSession {
    public static GetBinaryID(): number {return  241};
    public static GetMessageType(): string {return  "Command_GetAssetMarketData"};
    public SymbolName: string  = null;

    constructor() {
        super();
        this.BinaryID = 241;
    }
}

export class Command_Coprocessor_GetOrderStates extends Command_CustomSession {
    public static GetBinaryID(): number {return  242};
    public static GetMessageType(): string {return  "Command_Coprocessor_GetOrderStates"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 242;
    }
}

export class Answer_Coprocessor_Init extends Answer_CustomSession {
    public static GetBinaryID(): number {return  243};
    public static GetMessageType(): string {return  "Answer_Coprocessor_Init"};
    public Items: Answer_GetItems_Item[] = [];
    public Groups: Message_OrderStorage_Group[] = [];
    public MarketOrders: Message_OrderStorage_MarketOrder[] = [];
    public PendingOrders: Message_OrderStorage_PendingOrder[] = [];
    public Positions: Message_OrderStorage_Position[] = [];
    public AccountIds: Integer_Item[] = [];
    public ExternalGroups: Message_ExternalGroup[] = [];
    public SystemCurrency: Message_SystemCurrency[] = [];
    public ServerConfig: ServerConfigs[] = [];

    constructor() {
        super();
        this.BinaryID = 243;
    }
}

export class Command_Coprocessor_CloseOrderRequest extends Command_CustomSession {
    public static GetBinaryID(): number {return  244};
    public static GetMessageType(): string {return  "Command_Coprocessor_CloseOrderRequest"};
    public CloseTime: Date  = new Date();
    public ClosePrice: number  = 0;
    public RequestPrice: number  = 0;
    public Deviation: number  = 0;
    public Volume: number  = 0;
    public OrderID: number  = 0;
    public ItemID: number  = 0;
    public AccountID: number  = 0;
    public OrderCommandReason: number  = 0;

    constructor() {
        super();
        this.BinaryID = 244;
    }
}

export class Command_Deal_OpenPendingOrder extends Command_Deal_Common {
    public static GetBinaryID(): number {return  245};
    public static GetMessageType(): string {return  "Command_Deal_OpenPendingOrder"};
    public Volume: number  = 0;
    public TakeProfit: number  = 0;
    public StopLoss: number  = 0;
    public TrailingStop: number  = 0;
    public OpenPrice: number  = 0;
    public OrderType: number  = 0;
    public ExpirationDate: Date  = new Date();
    public ContractSize: number  = 0;
    public ContractSizeTypeId: number  = 0;

    constructor() {
        super();
        this.BinaryID = 245;
    }
}

export class Command_WithdrawalRequest extends Command_CustomSession {
    public static GetBinaryID(): number {return  246};
    public static GetMessageType(): string {return  "Command_WithdrawalRequest"};
    public TraderID: number  = 0;
    public AmountToWithdraw: number  = 0;
    public CurrencyToWithdraw: string  = null;
    public AccountName: string  = null;
    public AccountNumber: string  = null;
    public BankCode: string  = null;
    public BankName: string  = null;
    public BankAddress: string  = null;
    public ClientRegisteredAddress: string  = null;
    public MethodType: number  = 0;
    public DestinationAddress: string  = null;
    public Comments: string  = null;

    constructor() {
        super();
        this.BinaryID = 246;
    }
}

export class Answer_WithdrawalRequest extends Answer_CustomSession {
    public static GetBinaryID(): number {return  247};
    public static GetMessageType(): string {return  "Answer_WithdrawalRequest"};
    public RequestID: number  = 0;
    public RequestType: string  = null;

    constructor() {
        super();
        this.BinaryID = 247;
    }
}

export class Command_PriceHub_RetrieveLastTicksForSymbol extends Message_CustomCommand {
    public static GetBinaryID(): number {return  248};
    public static GetMessageType(): string {return  "Command_PriceHub_RetrieveLastTicksForSymbol"};
    public SymbolID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 248;
    }
}

export class Tick_ItemExt extends ProtoV2MessageBase {
    public static GetBinaryID(): number {return  249};
    public static GetMessageType(): string {return  "Tick_ItemExt"};
    public Timestamp: Date  = new Date();
    public ItemId: number  = 0;
    public Ask: number  = 0;
    public Bid: number  = 0;
    public ConversionToUSD: number  = 0;

    constructor() {
        super();
        this.BinaryID = 249;
    }
}

export class Answer_PriceHub_RetrieveLastTicksForSymbol extends Answer_CustomSession {
    public static GetBinaryID(): number {return  250};
    public static GetMessageType(): string {return  "Answer_PriceHub_RetrieveLastTicksForSymbol"};
    public TickItems: Tick_ItemExt[] = [];

    constructor() {
        super();
        this.BinaryID = 250;
    }
}

export class Command_PriceHub_RetrieveLastTicksForTradeGroup extends Message_CustomCommand {
    public static GetBinaryID(): number {return  251};
    public static GetMessageType(): string {return  "Command_PriceHub_RetrieveLastTicksForTradeGroup"};
    public TradeGroupID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 251;
    }
}

export class Answer_PriceHub_RetrieveLastTicksForTradeGroup extends Answer_CustomSession {
    public static GetBinaryID(): number {return  252};
    public static GetMessageType(): string {return  "Answer_PriceHub_RetrieveLastTicksForTradeGroup"};
    public TickItems: Tick_ItemExt[] = [];

    constructor() {
        super();
        this.BinaryID = 252;
    }
}

export class Command_PriceHub_RetrieveLastTicksForSymbols extends Message_CustomCommand {
    public static GetBinaryID(): number {return  253};
    public static GetMessageType(): string {return  "Command_PriceHub_RetrieveLastTicksForSymbols"};
    public SymbolIDs: string  = null;

    constructor() {
        super();
        this.BinaryID = 253;
    }
}

export class Answer_PriceHub_RetrieveLastTicksForSymbols extends Answer_CustomSession {
    public static GetBinaryID(): number {return  254};
    public static GetMessageType(): string {return  "Answer_PriceHub_RetrieveLastTicksForSymbols"};
    public TickItems: Tick_ItemExt[] = [];

    constructor() {
        super();
        this.BinaryID = 254;
    }
}

export class Command_PingPong extends Message_CustomCommand {
    public static GetBinaryID(): number {return  255};
    public static GetMessageType(): string {return  "Command_PingPong"};
    constructor() {
        super();
        this.BinaryID = 255;
    }
}

export class Answer_PingPong extends Answer_CustomSession {
    public static GetBinaryID(): number {return  256};
    public static GetMessageType(): string {return  "Answer_PingPong"};
    constructor() {
        super();
        this.BinaryID = 256;
    }
}

export class Command_Deal_UpdatePendingOrder extends Command_Deal_Common {
    public static GetBinaryID(): number {return  257};
    public static GetMessageType(): string {return  "Command_Deal_UpdatePendingOrder"};
    public OrderID: number  = 0;
    public Volume: number  = 0;
    public TakeProfit: number  = 0;
    public StopLoss: number  = 0;
    public TrailingStop: number  = 0;
    public OpenPrice: number  = 0;
    public OrderType: number  = 0;
    public ActualDealType: number  = 0;
    public VolumeDecimalPlaces: number  = 0;
    public ExpirationDate: Date  = new Date();

    constructor() {
        super();
        this.BinaryID = 257;
    }
}

export class Command_GetTraderChallengeSettings extends Command_CustomSession {
    public static GetBinaryID(): number {return  258};
    public static GetMessageType(): string {return  "Command_GetTraderChallengeSettings"};
    constructor() {
        super();
        this.BinaryID = 258;
    }
}

export class Command_GetTraderChallengeEODSnapshots extends Command_CustomSession {
    public static GetBinaryID(): number {return  259};
    public static GetMessageType(): string {return  "Command_GetTraderChallengeEODSnapshots"};
    constructor() {
        super();
        this.BinaryID = 259;
    }
}

export class Notification_TraderReadOnly extends Notification_CustomSession {
    public static GetBinaryID(): number {return  260};
    public static GetMessageType(): string {return  "Notification_TraderReadOnly"};
    constructor() {
        super();
        this.BinaryID = 260;
    }
}

export class Command_Deal_DeletePendingOrder extends Command_Deal_Common {
    public static GetBinaryID(): number {return  261};
    public static GetMessageType(): string {return  "Command_Deal_DeletePendingOrder"};
    public OrderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 261;
    }
}

export class Command_Deal_ResumePendingOrder extends Command_Deal_Common {
    public static GetBinaryID(): number {return  262};
    public static GetMessageType(): string {return  "Command_Deal_ResumePendingOrder"};
    public OrderID: number  = 0;
    public ResumePrice: number  = 0;
    public Volume: number  = 0;

    constructor() {
        super();
        this.BinaryID = 262;
    }
}

export class Command_Rollover extends Command_CustomSession {
    public static GetBinaryID(): number {return  263};
    public static GetMessageType(): string {return  "Command_Rollover"};
    public OrderID: number  = 0;
    public TraderID: number  = 0;
    public CommandOriginID: number  = 0;
    public CommandSenderID: number  = 0;
    public CommandTime: Date  = new Date();
    public Commission: number  = 0;
    public SymbolID: number  = 0;
    public ClientOrderID: string  = null;
    public Storage: number  = 0;

    constructor() {
        super();
        this.BinaryID = 263;
    }
}

export class Command_Coprocessor_Dump extends Command_CustomSession {
    public static GetBinaryID(): number {return  264};
    public static GetMessageType(): string {return  "Command_Coprocessor_Dump"};
    public DumpType: string  = null;

    constructor() {
        super();
        this.BinaryID = 264;
    }
}

export class Answer_Coprocessor_Dump extends Command_CustomSession {
    public static GetBinaryID(): number {return  265};
    public static GetMessageType(): string {return  "Answer_Coprocessor_Dump"};
    public Content: string  = null;

    constructor() {
        super();
        this.BinaryID = 265;
    }
}

export class Command_Coprocessor_Debug extends Command_CustomSession {
    public static GetBinaryID(): number {return  266};
    public static GetMessageType(): string {return  "Command_Coprocessor_Debug"};
    public Content: string  = null;

    constructor() {
        super();
        this.BinaryID = 266;
    }
}

export class Notification_AccountDisabledState extends Notification_CustomSession {
    public static GetBinaryID(): number {return  267};
    public static GetMessageType(): string {return  "Notification_AccountDisabledState"};
    public TraderID: number  = 0;
    public IsDisabled: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 267;
    }
}

export class Notification_AccountLoginInfo extends Notification_CustomSession {
    public static GetBinaryID(): number {return  268};
    public static GetMessageType(): string {return  "Notification_AccountLoginInfo"};
    public TraderID: number  = 0;
    public IPAddress: string  = null;
    public IsLocked: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 268;
    }
}

export class Command_SafeCharge_OpenOrder extends Command_CustomSession {
    public static GetBinaryID(): number {return  269};
    public static GetMessageType(): string {return  "Command_SafeCharge_OpenOrder"};
    constructor() {
        super();
        this.BinaryID = 269;
    }
}

export class Answer_SafeCharge_OpenOrder extends Answer_CustomSession {
    public static GetBinaryID(): number {return  270};
    public static GetMessageType(): string {return  "Answer_SafeCharge_OpenOrder"};
    public Token: string  = null;

    constructor() {
        super();
        this.BinaryID = 270;
    }
}

export class Command_Coprocessor_Subscribe extends Command_CustomSession {
    public static GetBinaryID(): number {return  271};
    public static GetMessageType(): string {return  "Command_Coprocessor_Subscribe"};
    public Subscription: string  = null;
    public SubscriptionIdKey: number  = 0;

    constructor() {
        super();
        this.BinaryID = 271;
    }
}

export class Command_Coprocessor_Unsubscribe extends Answer_CustomSession {
    public static GetBinaryID(): number {return  272};
    public static GetMessageType(): string {return  "Command_Coprocessor_Unsubscribe"};
    public Subscription: string  = null;
    public SubscriptionIdKey: number  = 0;

    constructor() {
        super();
        this.BinaryID = 272;
    }
}

export class Notification_AccountInfo extends Notification_CustomSession {
    public static GetBinaryID(): number {return  273};
    public static GetMessageType(): string {return  "Notification_AccountInfo"};
    public TraderID: number  = 0;
    public Equity: number  = 0;
    public Balance: number  = 0;
    public Margin: number  = 0;
    public FreeMargin: number  = 0;
    public UPL: number  = 0;
    public PureProfit: number  = 0;
    public ExternalAccountID: string  = null;

    constructor() {
        super();
        this.BinaryID = 273;
    }
}

export class Notification_ExtOrderStates extends Notification_OrderStates {
    public static GetBinaryID(): number {return  274};
    public static GetMessageType(): string {return  "Notification_ExtOrderStates"};
    public TraderID: number  = 0;
    public ExternalAccountID: string  = null;

    constructor() {
        super();
        this.BinaryID = 274;
    }
}

export class Notification_AccountReadonlyState extends Notification_CustomSession {
    public static GetBinaryID(): number {return  275};
    public static GetMessageType(): string {return  "Notification_AccountReadonlyState"};
    public TraderID: number  = 0;
    public IsReadonly: boolean  = false;

    constructor() {
        super();
        this.BinaryID = 275;
    }
}

export class Reply_AnswerLoginSuccess extends Command_CustomSession {
    public static GetBinaryID(): number {return  276};
    public static GetMessageType(): string {return  "Reply_AnswerLoginSuccess"};
    constructor() {
        super();
        this.BinaryID = 276;
    }
}

export class Command_GetAccountSnapshots extends Command_CustomSession {
    public static GetBinaryID(): number {return  277};
    public static GetMessageType(): string {return  "Command_GetAccountSnapshots"};
    constructor() {
        super();
        this.BinaryID = 277;
    }
}

export class Answer_GetAccountSnapshots extends Answer_CustomSession {
    public static GetBinaryID(): number {return  278};
    public static GetMessageType(): string {return  "Answer_GetAccountSnapshots"};
    public AccountSnapshots: Notification_AccountInfo[] = [];

    constructor() {
        super();
        this.BinaryID = 278;
    }
}

export class Command_ReloadAccount extends Command_CustomSession {
    public static GetBinaryID(): number {return  279};
    public static GetMessageType(): string {return  "Command_ReloadAccount"};
    public TraderID: number  = 0;

    constructor() {
        super();
        this.BinaryID = 279;
    }
}


